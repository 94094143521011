import React, {useEffect} from "react";
import {useStores} from "../store";
import CartItemList from "../components/CartItemList/CartItemList";
import {observer} from "mobx-react-lite";

const CartItemListContainer = () => {
  const { store } = useStores();

  useEffect(() => {}, []);

  return (
    <div>
      {store.cart.items.length == 0 ? (
        <div>Ваша корзина пуста</div>
      ) : (
        <div>
          <CartItemList
            onIncrease={(id) => {
              store.cart.increase(id);
            }}
            onDecrease={(id) => {
              store.cart.decrease(id);
            }}
            items={store.cart.items}
            onRemove={(id) => {
              store.cart.remove(id);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default observer(CartItemListContainer);
