import React, {ReactNode} from "react";
import "./FormSection.css";

interface FormSectionProps {
  children: ReactNode;
  title?: string;
}

const FormSection = (props: FormSectionProps) => {
  return (
    <div className={"form-section"}>
      <div className={"form-section__title"}>{props.title}</div>
      <div className={"form-section__body"}>{props.children}</div>
    </div>
  );
};

export default FormSection;
