import React, {useState} from 'react';
import './Notification.css';
import classNames from "classnames";
import {NotificationModel, NotificationType} from "../../types/NotificationModel";

interface NotificationProps {
   model: NotificationModel;
   onClose: (model: NotificationModel) => void;
}

const Notification = (props: NotificationProps) => {
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        props.onClose(props.model);
    }
    return (
        <>{show && <div className={classNames({
            "notification": true,
            "notification--warning": props.model.type == NotificationType.Warning
        })}>
            {props.model.text}
            <span className="notification__close" onClick={handleClose}></span>
        </div>}</>
    );
}

export default Notification;
