import React, {ReactNode} from "react";
import classNames from "classnames";
import "./Link.css";

export enum LinkStyle {
  Rounded,
  Primary,
  Button,
}

interface LinkProps {
  to: string;
  children: ReactNode;
  style?: LinkStyle[];
  blank?:boolean;
}

const Link = (props: LinkProps) => {
  return (
    <a
        target={props.blank ? "_blank" : ""}
      className={classNames({
        link: true,
        "link--button":
          props.style && props.style.indexOf(LinkStyle.Button) > -1,
        "link--rounded":
          props.style && props.style.indexOf(LinkStyle.Rounded) > -1,
        "link--primary":
          props.style && props.style.indexOf(LinkStyle.Primary) > -1,
      })}
      href={props.to}
    >
      {props.children}
    </a>
  );
};

export default Link;
