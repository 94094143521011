import React, {useState} from 'react';
import {SaleModel} from "../../types/SaleModel";
import moment from "moment";
import SaleStatus from "../SaleStatus/SaleStatus";
import "./SalesTable.css"
import NumberFormat from "react-number-format";
import classNames from "classnames";
import SaleProductsTable from "../SaleProductsTable/SaleProductsTable";

interface SalesTableProps {
  items: SaleModel[]
}

interface SalesTableHeader {
  data: string,
  width: string | number
}

const headers: SalesTableHeader[] = [
      { data: "Дата", width: 200},
      { data: "Баллы", width: 120},
      { data: "Кол-во", width: 100},
      { data: "Статус", width: 300 },
      { data: "Номер договора", width: "auto"},
    ];

const SalesTable = ({items}: SalesTableProps ) => {

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  const toggleExpanded = (index: number) => {
    let indexOf = expandedIndexes.indexOf(index);
    if(indexOf === -1 ) {
      expandedIndexes.push(index);
    } else {
      expandedIndexes.splice(indexOf, 1);
    }
    console.log('')
    setExpandedIndexes([...expandedIndexes]);
  }
  return (
    <div>
      {items.length === 0 && <div>У вас пока нет продаж</div>}

      {items.length !== 0 && (
        <div className="sales-table">
          <div className="sales-table__header">
            <div className="sales-table__header-row">
              {headers.map(item => <div className="sales-table__header-cell" style={{width: item.width}}>{item.data}</div> )}
            </div>
          </div>
          <div className="sales-table__body">
            {items.map((sale, ind) => <><div key={sale.id} className="sales-table__body-row  sales-table__body-row--zebra" onClick={() => toggleExpanded(ind)}>
              <div className="sales-table__body-cell sales-table__body-cell--blue" style={{width: headers[0].width}}>
                <i className={classNames({"arrow": true, "arrow--down": expandedIndexes.indexOf(ind) === -1, "arrow--up": expandedIndexes.indexOf(ind) !== -1 })}></i>
                {moment(sale.createdDate).format("DD.MM.YY HH:mm")}</div>
              <div className="sales-table__body-cell" style={{width: headers[1].width}}>+ <NumberFormat value={sale.total} thousandSeparator=" " displayType="text"/></div>
              <div className="sales-table__body-cell" style={{width: headers[2].width}}>{sale.productQuantity}</div>
              <div className="sales-table__body-cell" style={{width: headers[3].width}}>{<SaleStatus status={sale.status} />}</div>
              <div className="sales-table__body-cell" style={{width: headers[4].width}}>{sale.agreementNumber}</div>
            </div>
              <div className={classNames({"sales-table__sale-products": true, "sales-table__sale-products--expanded": expandedIndexes.indexOf(ind) !== -1})}>
                <SaleProductsTable items={sale.products} />
              </div>

            </> )}

          </div>
        </div>

        // <Table
        //   isZebra
        //   header={[
        //     { data: "Дата", type: "date" },
        //     { data: "Баллы", type: "topUp"  },
        //     { data: "Кол-во" },
        //     { data: "Статус" },
        //     { data: "Номер договора" },
        //   ]}
        //   rows={items.map((sale) => {
        //     return {
        //       key: sale.id.toString(),
        //       columns: [
        //         {
        //           data: moment(sale.createdDate).format("DD.MM.YY HH:mm"),
        //         },
        //         { data: sale.total },
        //         { data: sale.productQuantity },
        //         { data: <SaleStatus status={sale.status} /> },
        //         { data: sale.agreementNumber }
        //       ],
        //     };
        //   })}
        // />
      )}
    </div>
  );
};

export default SalesTable;
