import {httpGet, httpPostJson, httpPostJsonFile} from "../helpers/http";
import {AddOrderRequestModel} from "../types/AddOrderRequestModel";
import {CodeModel} from "../types/CodeModel";
import {OrderEventModel} from "../types/OrderEventModel";

export const addOrder = (
    request: AddOrderRequestModel[]
): Promise<Response> => {
    return httpPostJson(`/api/v1/orders/`, JSON.stringify(request));
};

export const resend = (
   id: number
): Promise<Response> => {
    return httpPostJson(`/api/v1/orders/${id}/resend`, "");
};

export const get = (id: number): Promise<OrderEventModel> => {
    return httpGet("/api/v1/orders/" + id);
};

export const downloadItem = (
    id: number,
    itemId: number
): Promise<Response> => {
    return httpPostJsonFile(`/api/v1/orders/${id}/items/${itemId}/download`, "");
};