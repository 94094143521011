import React from "react";
import "./ConfirmationOrderModalWindow.css";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import ModalWindow from "../ModalWindow/ModalWindow";
import {RequestState} from "../../types/RequestState";

interface ConfirmationOrderModalWindowProps {
  onClose: () => void;
  onCreateOrder: () => void;
  createOrderRequestState: RequestState;
  orderDisabled: boolean;
}

const ConfirmationOrderModalWindow = (
  props: ConfirmationOrderModalWindowProps
) => {
  return (
    <ModalWindow onClickOutside={props.onClose}>
      <div className={"confirmation-order-modal-window"}>
        <div className={"confirmation-order-modal-window__title"}>
          Подтвердить заказ?
        </div>
        <div className={"confirmation-order-modal-window__body"}>
          <div className={"confirmation-order-modal-window__button"}>
            <Button
              onClick={props.onClose}
              size={ButtonSize.Big}
              style={ButtonStyle.Primary}
              type={[ButtonType.Transparent]}
              disabled={props.createOrderRequestState == RequestState.Pending}
            >
              Я передумал
            </Button>
          </div>
          <div className={"confirmation-order-modal-window__button"}>
            <Button
                disabled={props.orderDisabled}
              onClick={props.onCreateOrder}
              size={ButtonSize.Big}
              style={ButtonStyle.Primary}
            >
              Конечно!
            </Button>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ConfirmationOrderModalWindow;
