import React from "react";
import CartWidget from "../components/CartWidget/CartWidget";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";

const CartWidgetContainer = () => {
  const { store } = useStores();
  return (
    <CartWidget points={store.participants.balance} sum={store.cart.total} />
  );
};

export default observer(CartWidgetContainer);
