import React, {useEffect} from 'react';
import './ActivateCodePage.css';
import {useStores} from "../store";
import Table, {TableHeaderColumn} from "../components/Table/Table";
import {CodeModel} from "../types/CodeModel";
import {observer} from "mobx-react-lite";
import Moment from "moment";
import ActivateCode from "../components/ActivateCode/ActivateCode";
import Title from "../components/Title/Title";

const columns: TableHeaderColumn[] = [
    {
        data: 'Дата активации'
    },
    {
        data: 'Код'
    },
    {
        data: 'Сумма'
    }
]

const ActivateCodePage = () => {
    const {store} = useStores();

    useEffect(() => {
        store.codes.getCodes();
    }, []);


    function mapToTableRows(codes: CodeModel[]) {
        return codes.map(x => ({
            key: x.id.toString(), columns: [
                {
                    data: Moment(x.activatedDate).format('DD.MM.yyyy HH:mm')
                },
                {
                    data: x.value
                },
                {
                    data: x.amount
                },
            ]
        }));
    }

    const handleActivatedCode = () => {
        store.codes.getCodes();
    }

    return (
        <div className={"activate-code-page"}>
            <div className={"activate-code-page__title"}>
                <Title as={"h1"}>Активировать код</Title>
            </div>
            <div  className={"activate-code-page__body"}>
                <ActivateCode onActivated={handleActivatedCode}/>
                <Title as="h3">История активации</Title>
                <Table header={columns} isZebra rows={mapToTableRows(store.codes.codes)}/>
            </div>
        </div>
    );
};

export default observer(ActivateCodePage);
