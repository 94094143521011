import React from "react";
import "./ConfirmationResendOrderToEmailModalWindow.css";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import ModalWindow from "../ModalWindow/ModalWindow";

interface ConfirmationOrderModalWindowProps {
  onClose: () => void;
  onResend: () => void;
  answer: string;
  email: string
}

const ConfirmationResendOrderToEmailModalWindow = (
  props: ConfirmationOrderModalWindowProps
) => {

  return (
    <ModalWindow onClickOutside={props.onClose}>
      <div className={"confirmation-resend-order-to-email-modal-window"}>
        <div className={"confirmation-resend-order-to-email-modal-window__title"}>
          {props.answer}
        </div>
        <div className={"confirmation-resend-order-to-email-modal-window__body"}>
          <div className={"confirmation-resend-order-to-email-modal-window__content"}>
            {props.email}
          </div>
          <div className={"confirmation-resend-order-to-email-modal-window__button"}>
            <Button
                onClick={props.onResend}
                size={ButtonSize.Big}
                style={ButtonStyle.Primary}
            >
              Отправить
            </Button>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ConfirmationResendOrderToEmailModalWindow;
