import React, {ReactNode} from "react";
import "./Button.css";
import classNames from "classnames";

export enum ButtonType {
  Block,
  Transparent,
}

export enum ButtonStyle {
  Primary,
  White,
  Black,
  Red,
}

export enum ButtonSize {
  Big,
  Middle,
  Small,
}

interface ButtonProps {
  children: ReactNode;
  onClick: () => void;
  type?: ButtonType[];
  size: ButtonSize;
  style: ButtonStyle;
  disabled?: boolean;
  pending?:boolean;
}

const Button = (props: ButtonProps) => {
  const getButtonStyle = () => {
    let classes = classNames({
      button: true,
      "button--big": props.size === ButtonSize.Big,
      "button--middle": props.size === ButtonSize.Middle,
      "button--primary": props.style === ButtonStyle.Primary,
      "button--white": props.style === ButtonStyle.White,
      "button--black": props.style === ButtonStyle.Black,
      "button--red": props.style === ButtonStyle.Red,
    });

    props.type?.forEach((type) => {
      if (type === ButtonType.Block) classes += classNames(" button--block");
      if (type === ButtonType.Transparent)
        classes += classNames(" button--transparent");
    });

    return classes;
  };

  return (
    <button
      disabled={props.disabled || props.pending}
      className={getButtonStyle()}
      onClick={props.onClick}
    >
      <span className={"button__children"}>
        <span className={"button__wrap"}>
          <span className={"button__text"}>{props.children}</span>
        </span>
      </span>
    </button>
  );
};

export default Button;
