import React from "react";
import "./FaceValueSelector.css";
import {formatFaceValue, GiftCertificateFaceValueDto} from "../../types/FaceValueModel";
import classNames from "classnames";

interface FaceValueSelectorProps {
  faceValues: GiftCertificateFaceValueDto[];
  selectedFaceValue?: GiftCertificateFaceValueDto;
  onSelect: (faceValueModel: GiftCertificateFaceValueDto) => void;
  title: string
}

const FaceValueSelector = (props: FaceValueSelectorProps) => {
  return (
    <div className={"face-value-selector"}>
      <div className={"face-value-selector__title"}>{props.title}</div>
      <div className={"face-value-selector__body"}>
        {props.faceValues.sort((a, b) =>  a.price > b.price && 1 || -1).map((faceValue) => (
          <div
            key={faceValue.faceValue.id}
            className={classNames({
              "face-value-selector__face-value": true,
              "face-value-selector__face-value--selected":
                faceValue.faceValue.id === props.selectedFaceValue?.faceValue.id,
            })}
            onClick={()=>{props.onSelect(faceValue)}}
          >
            {formatFaceValue(faceValue.faceValue)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaceValueSelector;
