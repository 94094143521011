import React from "react";

const AboutCompanyPage = () => {
  return (
    <div className={"cart-page"}>
      <h1>О компании Grohe</h1>
      <p></p>
      <p>
        GROHE десятилетиями выпускает на рынок многочисленные сантехнические
        изделия для ванной комнаты и кухни – новаторские, конструктивно
        продуманные, отмеченные наградами. Специалисты GROHE выполняют литье,
        гальванизацию, сборку, шлифовку и покрытие изделий, доводя их до
        совершенства. Из сочетания подлинного мастерства и сосредоточенной
        интеллектуальной работы рождается непревзойденная продукция, которая
        впечатляет всегда актуальным дизайном, долговечным качеством и
        уникальными функциями, создающими чистое удовольствие от общения с водой
        – “Pure Freude an Wasser”.
      </p>
      <h2>ЦЕННОСТИ НАШЕГО БРЕНДА</h2>
      <p>
        Дизайн, качество, технологии и экологичность. Четыре составляющие нашего
        бренда, лежащие в основе производства изделий GROHE. Мы устанавливаем
        самые высокие стандарты, чтобы обеспечить вам непревзойденное
        удовольствие каждый раз когда Вы включаете смеситель GROHE или
        принимаете душ.
      </p>
    </div>
  );
};

export default AboutCompanyPage;
