import {AuthStore} from "./AuthStore";
import {CatalogStore} from "./CatalogStore";
import {ParticipantStore} from "./ParticipantStore";
import {CartStore} from "./CartStore";
import {WaveStore} from "./WaveStore";
import {OrderStore} from "./OrdersStore";
import {CodesStore} from "./CodesStore";
import {NotificationsStore} from "./NotificationsStore";

export class RootStore {
  public auth: AuthStore;
  public catalog: CatalogStore;
  public participants: ParticipantStore;
  public cart: CartStore;
  public waves: WaveStore;
  public orders: OrderStore;
  public codes: CodesStore;
  public serverNotifications: NotificationsStore;
  constructor() {
    this.auth = new AuthStore(this);
    this.catalog = new CatalogStore(this);
    this.participants = new ParticipantStore(this);
    this.cart = new CartStore(this);
    this.waves = new WaveStore(this);
    this.orders = new OrderStore(this)
    this.codes = new CodesStore(this)
    this.serverNotifications = new NotificationsStore(this);
  }
}
