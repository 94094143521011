import React, {useEffect, useState} from "react";
import "./UploadDropzone.css";
import {useDropzone} from "react-dropzone";
import ID from "../../helpers/id";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import {CSSTransition, TransitionGroup} from "react-transition-group";

interface UploadFile {
  id: string;
  preview: string;
}

interface UploadDropZoneProps{
  onDrop:(files:File[])=>void;
}

const UploadDropzone = (props:UploadDropZoneProps) => {
  const [files, setFiles] = useState<UploadFile[]>([]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const deleteImage = (id: string) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          return { id: ID(),  preview: URL.createObjectURL(file) };
        })
      );
      props.onDrop(acceptedFiles);
    },
  });

  return (
    <div className={"upload-dropzone"}>
      <div {...getRootProps({ className: "upload-dropzone__dropzone" })}>
        <input {...getInputProps()} />
        <div className={"upload-dropzone__body"}>
          <TransitionGroup className={"upload-dropzone__thumbs"}>
            {files.map((file) => (
              <CSSTransition
                key={file.id}
                timeout={500}
                classNames={"upload-dropzone__thumb"}
              >
                <div className={"upload-dropzone__thumb"} key={file.id}>
                  <img src={file.preview} />
                  <div
                    onClick={() => {
                      deleteImage(file.id);
                    }}
                    className={"upload-dropzone__thumb-close"}
                  >
                    &#10006;
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>

          <div className={"upload-dropzone__text"}>Перетащите файлы сюда</div>
          <div className={"upload-dropzone__text-small"}>
            JPEG, PNG не более 10МБ
          </div>
          <div className={"upload-dropzone__button"}>
            <Button
              onClick={() => {}}
              size={ButtonSize.Big}
              style={ButtonStyle.Primary}
              type={[ButtonType.Transparent]}
            >
              Выбрать файлы
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDropzone;
