import {SaleProductStatus} from "../types/SaleProductModel";

export function mapToString(value: SaleProductStatus ){
  switch (value) {
    case SaleProductStatus.Unknown:
      return "Неизвестный"
    case SaleProductStatus.Created:
      return "В обработке"
    case SaleProductStatus.Success:
      return "Зачислено"
    case SaleProductStatus.Rejected:
      return "Отклонен"

  }
}