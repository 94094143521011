import {httpGet, httpPostJson} from "../helpers/http";
import {NotificationModel} from "../types/NotificationModel";

export const getNotifications = (): Promise<NotificationModel[]> => {
  return httpGet("/api/v1/notifications")
      .catch(err => {
         throw new Error('');
      });
};

export const closeNotification = (notificationId: number): Promise<void> => {
  return httpPostJson(`/api/v1/notifications/${notificationId}/close`, JSON.stringify({}));
};
