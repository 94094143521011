import React, {useEffect, useState} from "react";
import "./ProfilePage.css";
import Title from "../components/Title/Title";
import Alert, {AlertType} from "../components/Alert/Alert";
import Paragraph from "../components/Paragraph/Paragraph";
import Form from "../components/Form/Form";
import FormRow from "../components/Form/FormRow";
import TextInput from "../components/TextInput/TextInput";
import {useStores} from "../store";
import IncomeTaxDataFormContainer from "../containers/IncomeTaxDataFormContainer";
import {observer} from "mobx-react-lite";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../components/Button/Button";
import AlertRow from "../components/Alert/AlertRow";
import AlertColumn from "../components/Alert/AlertColumn";
import FlexSeparator from "../components/Flex/FlexSeparator";
import {RequestState} from "../types/RequestState";
import SelectList from "../components/SelectList/SelectList";
import {Dealer, dealerItems} from "../types/Dealer";
import CurrentClientProfile from "../client-profile";
import AlertNdfl from "../components/AlertNdfl/AlertNdfl";


const ProfilePage = () => {
    const {store} = useStores();

    const [linkSent, setLinkSent] = useState<boolean>(false);
    const [email, setEmail] = useState<string | undefined>();
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);

    const [selectedDealer, setSelectedDealer] = useState<Dealer>();
    const [cardNumber, setCardNumber] = useState<string>();

    const resend = () => {
        store.participants.resendEmailConfirmLink()
            .then(() => {
                setLinkSent(true);
            });
    }

    useEffect(() => {
        if (store.participants) {
            setSelectedDealer(dealerItems.find(x => x.name == store.participants.profile?.dealer));
            setCardNumber(store.participants.profile?.cardNumber)

            setEmail(store.participants.profile?.email);
        }
    }, [store.participants.profile]);

    const handleDealerChange = (dealer: Dealer) => {
        setSelectedDealer(dealer);
        store.participants.updateDealer(dealer.name);
    }

    const handleCardNumberChange = () => {
        if (cardNumber) {
            let newCardNumber = cardNumber.replaceAll(/\D/ig, '');
            if (newCardNumber.length >= 16) {
                store.participants.updateCardNumber(newCardNumber)
                    .then(() => {
                        store.participants.profile!.cardNumber = newCardNumber;
                    });
            }
        }
    }
    const validateEmail = (value: string) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
    }

    const saveEmail = () => {
        setEmailSent(true);
        console.log('emailSent && emailError', emailSent , emailError)
        if(email && !validateEmail(email)){
            return;
        }

        if (store.participants.profile && email ) {
            console.log('addEmail')
            store.participants.addEmail(email)
                .then(()=> {
                    console.log('then addEmail')
                    store.participants.profile!.email = email;
                });

        }
    }

    return (
        <div className={"profile-page"}>
            <div className={"profile-page__alerts"}>
                {!store.participants.profile?.email && (
                    <div className={"profile-page__alert"}>
                        <Alert type={AlertType.Warning}>
                            <AlertRow type={"end"}>
                                <AlertColumn>
                                    <Title as={"h4"} m={8}>
                                        Введите свою электронную почту
                                    </Title>
                                    <TextInput
                                        label={"Электронная почта"}
                                        id={"email"}
                                        editable
                                        isError={emailSent && emailError}
                                        onChange={(value) => {
                                            setEmailError(!validateEmail(value));
                                            setEmail(value);
                                        }}
                                        value={email}
                                    />
                                </AlertColumn>
                                <FlexSeparator/>
                                <AlertColumn>
                                    <Button
                                        onClick={saveEmail}
                                        size={ButtonSize.Big}
                                        style={ButtonStyle.Black}
                                        type={[ButtonType.Transparent]}
                                        pending={store.participants.resendEmailConfirmLinkState === RequestState.Pending}
                                    >
                                        Сохранить
                                    </Button>
                                </AlertColumn>
                            </AlertRow>
                        </Alert>
                    </div>
                )}

                {store.participants.profile?.email && !store.participants.profile?.emailConfirmed && (
                    <div className={"profile-page__alert"}>
                        <Alert type={AlertType.Warning}>
                            <AlertRow>
                                <AlertColumn>
                                    <Title as={"h4"} m={8}>
                                        Подтвердите свою электронную почту
                                    </Title>
                                    <Paragraph>
                                        На почту <b>{store.participants.profile?.email} отправлено</b> письмо для
                                        подтверждения
                                    </Paragraph>
                                </AlertColumn>
                                <FlexSeparator/>
                                <AlertColumn>
                                    {!linkSent && (<Button
                                        onClick={resend}
                                        size={ButtonSize.Big}
                                        style={ButtonStyle.Black}
                                        type={[ButtonType.Transparent]}
                                        pending={store.participants.resendEmailConfirmLinkState === RequestState.Pending}
                                    >
                                        Отправить письмо повторно
                                    </Button>)}
                                    {linkSent && (<div className={"profile-page__sent"}>Письмо отправлено</div>)}
                                </AlertColumn>
                            </AlertRow>
                        </Alert>
                    </div>
                )}

                {CurrentClientProfile.userProfile.ndfl && !store.participants.profile?.personalIncomeTaxData && (
                    <div className={"profile-page__alert"}>
                       <AlertNdfl/>
                    </div>
                )}
                {store.participants.addIncomeTaxDataRequestState == RequestState.Error &&
                <div className={"profile-page__alert"}>
                    <Alert type={AlertType.Warning}>
                        <Paragraph>
                            Заполните все данные для НДФЛ
                        </Paragraph>
                    </Alert>
                </div>}
            </div>
            <div className={"profile-page__title"}
                 style={{textAlign: CurrentClientProfile.userProfile.center ? "center" : "inherit"}}>
                <Title as={"h1"}> Настройки профиля </Title>
            </div>


            <div className={"profile-page__forms"}
                 style={{justifyContent: CurrentClientProfile.userProfile.center ? "center" : "space-between"}}>
                <div className={"profile-page__contact-form"}>
                    <Form title={"Контактные данные"}>
                        <FormRow>
                            <TextInput
                                label={"Логин"}
                                id={"login"}
                                onChange={() => {
                                }}
                                value={store.participants.profile?.userName}
                            />
                        </FormRow>
                        {!CurrentClientProfile.userProfile.ndfl && <FormRow>
                            <TextInput
                                label={"Имя"}
                                id={"name"}
                                onChange={() => {
                                }}
                                value={store.participants.profile?.firstname}
                            />
                        </FormRow>}
                        <FormRow>
                            <TextInput
                                label={"Электронная почта"}
                                id={"email"}
                                onChange={() => {
                                }}
                                value={store.participants.profile?.email}
                            />
                        </FormRow>
                        <FormRow>
                            <TextInput
                                label={"Телефон"}
                                id={"login"}
                                onChange={() => {
                                }}
                                value={store.participants.profile?.phoneNumber}
                            />
                        </FormRow>
                    </Form>
                    {CurrentClientProfile.userProfile.bankCard && store.participants.profile &&
                    <Form title={"Данные по банковской карте"}>
                        <FormRow>
                            <TextInput
                                label={"Номер банковской карты"}
                                id={"bankCard"}
                                onChange={(value) => setCardNumber(value)}
                                value={cardNumber}
                                mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
                                editable={!store.participants.profile?.cardNumber}
                            />
                            {!store.participants.profile?.cardNumber &&
                            <Button onClick={handleCardNumberChange} size={ButtonSize.Big}
                                    style={ButtonStyle.Primary}>Сохранить</Button>}
                        </FormRow>
                    </Form>}
                    {CurrentClientProfile.userProfile.dealer && <Form title={"Точка продаж"}>
                        <FormRow>
                            <SelectList<Dealer>
                                label="Дилер продукции"
                                id={"dealer"}
                                renderValue={(dealer) => dealer.name}
                                onSelect={handleDealerChange}
                                value={selectedDealer}
                                items={dealerItems}
                            />
                        </FormRow>
                        {/*<FormRow>*/}
                        {/*  <TextInput*/}
                        {/*    label={"Дилер продукции"}*/}
                        {/*    id={"dealer"}*/}
                        {/*    onChange={() => {}}*/}
                        {/*    value={store.participants.profile?.dealer}*/}
                        {/*  />*/}
                        {/*</FormRow>*/}
                        <FormRow>
                            <TextInput
                                label={"Название организации"}
                                id={"login"}
                                onChange={() => {
                                }}
                                value={store.participants.profile?.company}
                            />
                        </FormRow>
                        <FormRow>
                            <TextInput
                                label={"Город"}
                                id={"login"}
                                onChange={() => {
                                }}
                                value={store.participants.profile?.city}
                            />
                        </FormRow>
                        <FormRow>
                            <TextInput
                                label={"Адрес"}
                                id={"login"}
                                onChange={() => {
                                }}
                                value={store.participants.profile?.pointAddress}
                            />
                        </FormRow>
                    </Form>}
                </div>
                {CurrentClientProfile.userProfile.ndfl && <div className={"profile-page__ndfl-form"}>
                    <IncomeTaxDataFormContainer/>
                </div>}
            </div>
        </div>
    );
};

export default observer(ProfilePage);
