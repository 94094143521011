import React from "react";
import "./Arrow.css";
import classNames from "classnames";

export enum ArrowType {
  Right,
  Left,
  Up,
  Down,
}

interface ArrowProps {
  type: ArrowType;
  size?: number,
  className?: string

}

const Arrow = (props: ArrowProps) => {
  const classes = classNames({
    arrow: true,
    "arrow--up": props.type === ArrowType.Up,
    "arrow--down": props.type === ArrowType.Down,
    "arrow--left": props.type === ArrowType.Left,
    "arrow--right": props.type === ArrowType.Right,
  }, props.className);

  return <i className={classes} style={{padding: props.size || 2 }} />;
};

export default Arrow;
