import {FaceValueUnit} from "./types/FaceValueModel";
import {CLIENT_ID} from "./constants";
import MercedesLogo from "./assets/images/mercedes_logo.png";
import GroheLogo from "./assets/images/logo.png";
import MainLogo from "./assets/images/vektor-logo.png";
import BayerLogo from "./assets/images/bayer_logo.jpg";
import UpecoLogo from "./assets/images/upeco_logo.svg";
import MegapolisLogo from "./assets/images/megapolis.png";
import PmiLogo from "./assets/images/pmi-logo.png";
import JacobsLogo from "./assets/images/jacobs-logo.png";
import HeinekenLogo from "./assets/images/heineken-logo.png";
import {Property} from "csstype";

export interface ClientProfileHeader {
    height: number;
}

export interface ClientProfileOrder {
    allowDownload: boolean;
}

export interface LogoCss {
    image: string;
    width?: number;
    height?: number;
    backgroundColor?: Property.BackgroundColor;
    padding?: Property.Padding<string>;
}

export interface ClientProfile {
    icon?: string,
    title?: string,
    logo: LogoCss,
    userProfile: UserProfileSettings,
    banner: boolean,
    sales: boolean,
    booklets: boolean,
    about: boolean,
    defaultUrl?: string
    header?: ClientProfileHeader,
    order?: ClientProfileOrder
}

interface UserProfileSettings {
    bankCard: boolean
    ndfl: boolean
    dealer: boolean
    codes: boolean
    center?: boolean
}

const clientProfiles: Record<string, ClientProfile> = {
    "grohe-catalog-webui": {
        logo: {
            image: GroheLogo,
            width: 130,
            height: 100
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: true,
            codes: false
        },
        banner: true,
        sales: true,
        booklets: true,
        about: true,
        defaultUrl: "/waves/1/products",
        order: {
            allowDownload: false
        }
    },
    "mercedes-catalog-webui": {
        logo: {
            image: MercedesLogo,
            width: 52,
            height: 40
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "main-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "bayer-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: BayerLogo,
            width: 90,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 5px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "upeco-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: UpecoLogo,
            width: 125,
            height: 26,
            // backgroundColor: "#3884be",
            padding: "10px 0 0 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "gift-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "megapolis-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MegapolisLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: true,
            dealer: false,
            codes: true,
            center: false
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "avz-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: true,
            dealer: false,
            codes: true,
            center: false
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "pmi-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: PmiLogo,
            width: 40,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 0 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: false,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        header: {
            height: 80
        },
        order: {
            allowDownload: true
        }
    },
    "pmi-premium-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: PmiLogo,
            width: 40,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 0 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: false,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        header: {
            height: 80
        },
        order: {
            allowDownload: true
        }
    },
    "jacobs-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: JacobsLogo,
            width: 130,
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "otp-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "heineken-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: HeinekenLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: false,
            codes: false,
            center: false
        },
        banner: true,
        sales: false,
        booklets: false,
        about: false
    },
}

const CurrentClientProfile: ClientProfile = clientProfiles[CLIENT_ID];
export default CurrentClientProfile;


export const FaceValueUnitsName: Record<FaceValueUnit, string> = {
    [FaceValueUnit.Ruble]: 'руб.',
    [FaceValueUnit.Month]: 'мес.'
};