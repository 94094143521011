import React from "react";
import {BrowserRouter, Switch} from "react-router-dom";
import UnauthorizedLayout from "./pages/_UnauthorizedLayout";
import AuthorizedLayout from "./pages/_AuthorizedLayout";
import PublicRouteContainer from "./containers/PublicRouteContainer";
import PrivateRouteContainer from "./containers/PrivateRouteContainer";
import {Helmet} from "react-helmet";
import {CLIENT_NAME} from "./constants";
import CurrentClientProfile from "./client-profile";

function App() {
  return (
      <BrowserRouter>
          <Helmet>
              <title>{CurrentClientProfile.title || CLIENT_NAME }</title>
              <meta
                  name="description"
                  content={CLIENT_NAME + " программа лояльности"}
              />
          </Helmet>
        <Switch>
          <PublicRouteContainer
            path="/authentication"
            component={UnauthorizedLayout}
          />
          <PrivateRouteContainer path="/" component={AuthorizedLayout} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
