import React, {Fragment, ReactNode} from "react";
import "./Title.css";
import classNames from "classnames";

interface TitleProps {
  children: ReactNode;
  as: "h1" | "h2" | "h3" | "h4";
  thin?:boolean;
  m?: 8|25;
}

const Title = (props: TitleProps) => {
  return (
    <Fragment>
      {props.as === "h1" && (
        <h1 className={classNames({
          "title":true,
          "title-1":true,
          "title-thin": props.thin,
          ["title--m-"+props.m]:props.m
        })}>{props.children}</h1>
      )}
      {props.as === "h2" && (
          <h1 className={classNames({
            "title":true,
            "title-2":true,
            "title-thin": props.thin,
            "title--m-8":props.m === 8
          })}>{props.children}</h1>
      )}
      {props.as === "h3" && (
          <h1 className={classNames({
            "title":true,
            "title-3":true,
            "title-thin": props.thin,
            ["title--m-"+props.m]:props.m
          })}>{props.children}</h1>
      )}
      {props.as === "h4" && (
          <h1 className={classNames({
            "title":true,
            "title-4":true,
            "title-thin": props.thin,
            ["title--m-"+props.m]:props.m
          })}>{props.children}</h1>
      )}
    </Fragment>
  );
};

export default Title;
