import React from "react";
import {NavLink} from "react-router-dom";
import "./ProductListItem.css";
import img from "../../assets/images/Decanter-card.png";

interface ProductListItemProps{
    to:string;
    imagePath:string;
}

const ProductListItem = (props:ProductListItemProps) => {
  return (
    <div className={"product-list-item"}>
      <NavLink to={props.to}>
        <img className={"product-list-item__image"} src={props.imagePath} alt={"notFound"} />
      </NavLink>
    </div>
  );
};

export default ProductListItem;
