import React, {useEffect} from 'react';
import {useStores} from "../../store";
import Notification from "../Notification/Notification";
import {observer} from "mobx-react-lite";

const ServerNotifications = () => {
    const {store} = useStores();
    useEffect(() => {
        store.serverNotifications.getAll()
            .catch(err => {
                store.participants.getProfile()
                    .then(profile => {
                        window.location.assign(store.participants.profile?.client.baseUrl!);
                    });
            });
        let interval = setInterval(() => {
            store.serverNotifications.getAll()
                .catch(err => {
                    store.participants.getProfile()
                        .then(profile => {
                            window.location.assign(store.participants.profile?.client.baseUrl!);
                        });
                });;
        }, 10 * 60 * 1000)
        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <div className="server-notifications">
            {store.serverNotifications.notifications.slice(-3)
                .map(n => <Notification model={n} onClose={(model) => store.serverNotifications.close(model)}/>)}
        </div>
    );
}

export default observer(ServerNotifications);
