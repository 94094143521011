import {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState} from "react";

export function useClickOutside(initIsOpen: boolean ): [MutableRefObject<HTMLDivElement | null>, boolean, Dispatch<SetStateAction<boolean>>]
{
    const [isOpen, setIsOpen] = useState(initIsOpen);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    function handleClickOutside(event: any) {
        if (wrapperRef !== null && wrapperRef.current !== null) {
            if (!wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
    }

    return [wrapperRef, isOpen, setIsOpen];
}