import React from "react";
import "./WavesPage.css";
import Title from "../components/Title/Title";
import WaveCard from "../components/WaveCard/WaveCard";
import {NavLink} from "react-router-dom";

const WavesPage = () => {
  return (
    <div className={"wave-page"}>
      <div className={"wave-page__title"}>
        <Title as={"h1"}>Акции</Title>
      </div>
      <div className={"wave-page__text"}>
        Вы получите больше баллов за продажу товаров из акций
      </div>
      <div className={"wave-page__waves"}>
        <div className={"wave-page__wave"}>
            <NavLink to={"/waves/1"}>
                <WaveCard title={"Водные системы GROHE"} background={"https://storage.googleapis.com/vector-storage/public/wave-cards/1-bg-s.jpg"} />
            </NavLink>

        </div>
        <div className={"wave-page__wave"}>
          <WaveCard title={"Водные системы GROHE"} background={"https://storage.googleapis.com/vector-storage/public/wave-cards/1-bg-s.jpg"} />
        </div>
        <div className={"wave-page__wave"}>
          <WaveCard title={"Водные системы GROHE"} background={"https://storage.googleapis.com/vector-storage/public/wave-cards/1-bg-s.jpg"} />
        </div>
        <div className={"wave-page__wave"}>
          <WaveCard title={"Водные системы GROHE"} background={"https://storage.googleapis.com/vector-storage/public/wave-cards/1-bg-s.jpg"} />
        </div>
        <div className={"wave-page__wave"}>
          <WaveCard title={"Водные системы GROHE"} background={"https://storage.googleapis.com/vector-storage/public/wave-cards/1-bg-s.jpg"} />
        </div>
        <div className={"wave-page__wave"}>
          <WaveCard title={"Водные системы GROHE"} background={"https://storage.googleapis.com/vector-storage/public/wave-cards/1-bg-s.jpg"} />
        </div>
      </div>
    </div>
  );
};

export default WavesPage;
