import React from 'react';

const ThankForAddSalePage = () => {
    return (
        <div className={"thank-for-add-sale"}>
            <h1>dddd</h1>
        </div>
    );
};

export default ThankForAddSalePage;