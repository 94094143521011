import React, {useEffect, useMemo, useState} from "react";
import "./OrderEvent.css";
import {mapOrderStatus, OrderEventModel} from "../../types/OrderEventModel";
import Point, { PointSize } from "../Point/Point";
import {useStores} from "../../store";
import Moment from 'moment';
import {observer} from "mobx-react-lite";
import Link from "../Link/Link";
import {useHistory} from "react-router-dom";
import OrderStatus from "../OrderStatus/OrderStatus";
import OrderSenderToEmail from "../ResendOrderToEmail/ResendOrderToEmail";

interface OrderEventProps {
  order: OrderEventModel;
  cardImage?:string;
  email: string;
  handleResend: (id: number) => void;
  handleDownload: (id: number) => void;
}

const OrderEvent = (props: OrderEventProps) => {

  const [sum, setSum] = useState<number>(0);
  const history = useHistory();

  const goToDetails  = () => {
    history.push("/orders/" + props.order.id);
  }

  useEffect(() => {
    let sum: number = 0;
    props.order.giftCertificateItems.forEach((item) => {
      sum = sum + item.price;
    });
    setSum(sum);
  });
  return (
    <div className={"order-event"} onClick={goToDetails}>
      <div className={"order-event__image"}>
        <img alt={""} src={props.cardImage} />
      </div>
      <div className={"order-event__body"}>
        <div className={"order-event__title"}>Заказ №{props.order.number}</div>
        <div className={"order-event__products"}>
          {props.order.giftCertificateItems.map((x) => (
            <div className={"order-event__product"}>
              <div className={"order-event__product-name"}>
                {x.vendorName} {x.faceValueName}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={"order-event__description"}>
        <div className={"order-event__total"}>
          <Point amount={sum} size={PointSize.Big} />
        </div>
        <OrderStatus status={props.order.status}/>
        <OrderSenderToEmail status={props.order.status} small email={props.email} onResend={() => props.handleResend(props.order.id)} onDownload={() => props.handleDownload(props.order.id)}/>
        <div className={"order-event__date"}>{Moment(props.order.createdDate).format('DD.MM.yyyy HH:mm')}</div>
      </div>
    </div>
  );
};

export default OrderEvent;
