import React from 'react';
import "./ConfirmEmailBlock.css";
import {RequestState} from "../../types/RequestState";

interface ConfirmEmailBlockProps{
    state:RequestState
}

const ConfirmEmailBlock = (props:ConfirmEmailBlockProps) => {
    return (
        <div className={"confirm-email-block"}>
            {props.state === RequestState.Init && <div></div>}
            {props.state === RequestState.Pending && <div>Подтверждаем почту...</div>}
            {props.state === RequestState.Done && <div>Ваша почта успешно подтверждена</div>}
            {props.state === RequestState.Error && <div>Ваша почта не подтверждена</div>}
        </div>
    );
};

export default ConfirmEmailBlock;