import React from "react";
import "./Logo.css";
import {LogoCss} from "../../client-profile";

interface LogoProps {
  logo: LogoCss
}
const Logo = ({logo}:LogoProps) => {
  return <div className={"logo"} style={{backgroundColor: logo.backgroundColor, padding: logo.padding, width: logo.width  }}>
    <img  src={logo.image} style={{width: logo.width, height: logo.height}} alt=""/>
  </div>;
};

export default Logo;
