import React from "react";
import {Route, Switch} from "react-router-dom";
import AuthenticationPage from "./AuthenticationPage";

const UnauthorizedLayout = () => {
  return (
    <Switch>
      <Route path="/authentication/:action" component={AuthenticationPage} />
    </Switch>
  );
};

export default UnauthorizedLayout;
