import {RootStore} from "./RootStore";
import {makeAutoObservable, runInAction} from "mobx";
import {NotificationModel, NotificationType} from "../types/NotificationModel";
import {closeNotification, getNotifications} from "../services/NotificationsService";

export class NotificationsStore {
    private _rootStore: RootStore;
    notifications: NotificationModel[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this._rootStore = rootStore;
    }

    getAll = () => {
        return getNotifications()
            .then((response) => {
                runInAction(() => {
                    this.notifications = response;
                })
            });
            // .catch(err => {
            //     runInAction(() => {
            //         this.notifications.push({id: -1, text: "У Вас нет доступа! Обратитесь в службу поддержки.", type: NotificationType.Warning});
            //     });
            // });
    };


    close = (notification: NotificationModel): Promise<void> => {
        const index = this.notifications.indexOf(notification, 0);
        if (index > -1) {
            this.notifications.splice(index, 1);
        }
        return closeNotification(notification.id)
            .then((response) => {
                return response;
            });
    };
}
