import React, {useEffect, useState} from "react";
import "./Filter.css";
import classNames from "classnames";
import ID from "../../helpers/id";
import {CSSTransition} from "react-transition-group";
import {NavLink} from "react-router-dom";
import {useClickOutside} from "../../hooks/useClickOutside";

interface FilterProps {
    filters: string[];
    onChange: (filter: string | null) => void;
    maxVisibleCount: number
}

const Filter = (props: FilterProps) => {
    const [selected, setSelected] = useState<string | null>();
    const [wrapperRef, isOpen, setIsOpen] = useClickOutside(false);

    useEffect(() => {
            setSelected(null);
            props.onChange(null);
        }, []);

        const changeHandler = (filter: string | null) => {
            setSelected(filter);
            props.onChange(filter);
            setIsOpen(false);
        };

        const toggleIsOpen = () => {
            setIsOpen(!isOpen);
        }

        return (
            <div className={"filter"} >
                <div
                    className={classNames({
                        filter__item: true,
                        "filter__item--active": null === selected,
                    })}
                    onClick={() => {
                        changeHandler(null);
                    }}
                >
                    Все категории
                </div>
                {props.filters.slice(0, props.maxVisibleCount - 1).map((filter, index) => (
                    <div key={ID()}>
                        <div
                            key={filter}
                            className={classNames({
                                filter__item: true,
                                "filter__item--active": filter === selected,
                                "filter__item--bold": filter === 'Вывод средств',
                            })}
                            onClick={() => {
                                changeHandler(filter);
                            }}
                        >
                            {filter}
                        </div>
                    </div>
                ))}
                <div className="filter__expander" ref={wrapperRef}>
                    <div className="filter__item" onClick={toggleIsOpen}>
                        Еще...
                    </div>
                    <CSSTransition
                        in={isOpen}
                        unmountOnExit
                        classNames="submenu"
                        timeout={200}
                    >
                        <div className="filter__expander-submenu user-bar__submenu">
                            {props.filters.slice(props.maxVisibleCount - 1).map((filter, index) => (
                                <div key={ID()} className="user-bar__submenu-item">
                                    <div
                                        key={filter}
                                        className={classNames({
                                            "user-bar__submenu-link": true,
                                            "filter__item--active": filter === selected,
                                            "filter__item--bold": filter === 'Вывод средств',
                                        })}
                                        onClick={() => {
                                            changeHandler(filter);
                                        }}
                                    >
                                        {filter}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CSSTransition>
                </div>
            </div>
        );
    }
;

export default Filter;
