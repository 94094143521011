import React from 'react';
import "./ConfirmEmail.css";
import ConfirmEmailBlockContainer from "../containers/ConfirmEmailBlockContainer";
import {useLocation} from "react-router-dom";

const ConfirmEmail = () => {
    const location = useLocation()
    return (
        <div className={"confirm-email-page"}>
            <h1>Подтверждение почты</h1>
            <ConfirmEmailBlockContainer token={new URLSearchParams(location.search).get("token") || ""} />
        </div>
    );
};

export default ConfirmEmail;