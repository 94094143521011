import { OrderEventModel } from "./OrderEventModel";
import { IncreaseBalanceEventModel } from "./IncreaseBalanceEventModel";

export interface EventModel {
  type:EventType;
  date: Date;
  order: OrderEventModel | null;
  increaseBalance: IncreaseBalanceEventModel| null;
}

export enum EventType{
  Order,
  IncreaseBalance
}