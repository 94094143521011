import React from "react";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import {useHistory} from "react-router-dom";
import Authorize from "../Authorize/Authorize";
import CurrentClientProfile from "../../client-profile";

const AddSaleButton = () => {
    const history = useHistory();

    const go = () => {
        history.push("/waves/create");
    };

    return (
        <Authorize visible={CurrentClientProfile.sales}>
            <Button
                onClick={go}
                style={ButtonStyle.Primary}
                size={ButtonSize.Big}
                type={[ButtonType.Block]}
            >
                Добавить продажи
            </Button>
        </Authorize>
    );
};

export default AddSaleButton;
