import React from 'react';
import './OrderItem.css';
import {GiftCertificateItem} from "../../types/OrderEventModel";

interface OrderItemProps {
    item: GiftCertificateItem,
    cardImage: string
}

const OrderItem = (props: OrderItemProps) => {
    return (
        <div className="order-item">
            <div className="order-item__image">
                <img alt={props.item.vendorName} src={props.cardImage}/>
            </div>
            <div className={"order-item__name"}>
                {props.item.vendorName} {props.item.faceValueName}
            </div>
        </div>
    );
};

export default OrderItem;
