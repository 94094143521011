import React from "react";
import "./ThankForOrderPage.css";
import Button, {ButtonSize, ButtonStyle} from "../components/Button/Button";
import {useHistory} from "react-router-dom";

const ThankForOrderPage = () => {
  const history = useHistory();
  return (
    <div className={"thank-for-order-page"}>
      <div className={"thank-for-order-page__center"}>
        <div className={"thank-for-order-page__icon"}></div>
        <div className={"thank-for-order-page__title"}>Заказ принят</div>
        <div className={"thank-for-order-page__text"}>
          Письмо с сертификатом придет на почту в течение 3-5 рабочих дней, не считая дня заказа. Следите за статусом заказа и не забывайте проверять папку спам.
        </div>
        <div className={"thank-for-order-page__button"}>
          <Button
            onClick={() => {
              history.push("/events");
            }}
            size={ButtonSize.Big}
            style={ButtonStyle.Primary}
          >
            К списку покупок
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThankForOrderPage;
