import React, {ReactNode} from "react";
import "./Form.css";
import CurrentClientProfile from "../../client-profile";

interface FormProps {
  children: ReactNode;
  title?: string;
}

const Form = (props: FormProps) => {
  return (
    <div className={"form"}>
      {props.title && <div className={"form__title"} style={{textAlign: CurrentClientProfile.userProfile.center ? "center":"inherit"}}>{props.title}</div>}

      <div className={"form__body"}>{props.children}</div>
    </div>
  );
};

export default Form;
