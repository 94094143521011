import React, {ReactNode} from 'react';
import "./GridColumn.css";

interface GridColumnProps{
    children:ReactNode;
}

const GridColumn = (props:GridColumnProps) => {
    return (
        <div className={"grid-column"}>
            {props.children}
        </div>
    );
};

export default GridColumn;