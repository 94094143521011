import React, {MouseEvent, MouseEventHandler, useState} from 'react';
import './ResendOrderToEmail.css'
import logo from '../../assets/icons/letter.png'
import downloadLogo from '../../assets/icons/download.png'
import ConfirmationResendOrderToEmailModalWindow from "../ModalWindows/ConfirmationResendOrderToEmailModalWindow";
import CurrentClientProfile from "../../client-profile";

interface ResendOrderToEmail {
    email: string,
    onResend: () => void;
    small?: boolean;
    status: string;
    onDownload: () => void
}

const ResendOrderToEmail = (props: ResendOrderToEmail) => {
    const [showModalWindow, setShowModalWindow] = useState(false);
    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setShowModalWindow(true);
    }
    const handleResend = () => {
        setShowModalWindow(false);
        props.onResend();
    }

    const handleDownloadClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        props.onDownload();
    }

    const handleClose = () => {
        setShowModalWindow(false);
    }

    if(!(props.status == 'Delivered' || props.status == 'PartiallyDelivered')) {
        return <></>;
    }

    return (
        <>
            <div className="order-sender-to-email" onClick={handleClick}>
                <img className="order-sender-to-email__img" src={logo} alt="letter"/>
                <span
                    className="order-sender-to-email__text">{props.small ? "Отправить на почту" : "Отправить сертификаты на почту"}</span>
            </div>
            {CurrentClientProfile.order?.allowDownload && <div className="order-sender-to-email" onClick={handleDownloadClick}>
               <img className="order-sender-to-email__img" src={downloadLogo} alt="download"/>
               <span
                   className="order-sender-to-email__text">{props.small ? "Скачать" : "Скачать сертификаты"}</span>
            </div>}
            {showModalWindow &&
            <ConfirmationResendOrderToEmailModalWindow onClose={handleClose} onResend={handleResend}
                                                       answer="Отправить все сертификаты на почту?"
                                                       email={props.email}/>}
        </>
    );
}

export default ResendOrderToEmail;
