import React from "react";
import ProductListItem from "./ProductListItem";
import "./ProductList.css";
import {GiftCertificateModel} from "../../types/GiftCertificateModel";
import {CSSTransition, TransitionGroup} from "react-transition-group";

interface ProductListProps {
  giftCertificates: GiftCertificateModel[];
}

const ProductList = (props: ProductListProps) => {
  return (
    <TransitionGroup className={"product-list"}>
      {props.giftCertificates.map((giftCertificate) => (
        <CSSTransition
          key={giftCertificate.id}
          timeout={500}
          classNames={"product-list__item"}
        >
          <div key={giftCertificate.id} className={"product-list__item"}>
            <ProductListItem
              key={giftCertificate.id}
              to={"/products/" + giftCertificate.id}
              imagePath={giftCertificate.cardImage}
            />
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default ProductList;
