import React from "react";
import classNames from "classnames";
import "./SaleStatus.css";

interface SaleStatusProps {
  status: "Success" | "Created" | "Rejected" | "SuccessPart";
}

const SaleStatus = (props: SaleStatusProps) => {
  return (
    <div className={"sale-status"}>
      <div
        className={classNames({
          "sale-status__icon": true,
          "sale-status__icon--success": props.status === "Success" || props.status === "SuccessPart",
          "sale-status__icon--wait": props.status === "Created",
          "sale-status__icon--rejected": props.status === "Rejected",
        })}
      />
      <div className={"sale-status__text"}>
        {props.status === "Success" && <span>Зачислено</span>}
        {props.status === "SuccessPart" && <span>Зачислено частично</span>}
        {props.status === "Created" && <span>В обработке</span>}
        {props.status === "Rejected" && <span>Некорректно заполнена</span>}
      </div>
    </div>
  );
};

export default SaleStatus;
