import React from 'react';
import {SaleProductModel} from "../../types/SaleProductModel";
import "./SaleProductsTable.css"
import {mapToString} from "../../helpers/enums";

interface SaleProductsTableProps {
  items: SaleProductModel[]
}

interface SalesProductTableHeader {
  id: number,
  data: string,
  width: string | number
}

const headers: SalesProductTableHeader[] = [
  {id: 1, data: "Артикул", width: 100},
  {id: 2, data: "Кол-во", width: 90},
  {id: 3, data: "Наименование", width: 520},
  {id: 4, data: "Баллы", width: 70},
  {id: 5, data: "Статус", width: 200},
];


const SaleProductsTable = ({items}: SaleProductsTableProps) => {
  return (
    <div className="sale-products-table">
      <div className="sale-products-table__header">
        <div className="sale-products-table__header-row">
          {headers.map(h => <div key={h.id} className="sale-products-table__header-cell" style={{width: h.width}}>
            {h.data}
          </div>)}
        </div>
      </div>
      <div className="sale-products-table__body">
          {items.map(product => <div  key={product.id} className="sale-products-table__body-row">
            <div className="sale-products-table__body-cell" style={{width: headers[0].width}}>{product.vendorCode}</div>
            <div className="sale-products-table__body-cell" style={{width: headers[1].width}}>{product.quantity}</div>
            <div className="sale-products-table__body-cell" style={{width: headers[2].width}}>{product.name}</div>
            <div className="sale-products-table__body-cell" style={{width: headers[3].width}}>{product.point}</div>
            <div className="sale-products-table__body-cell" style={{width: headers[4].width}}>{mapToString(product.status)}</div>
          </div>)}
      </div>


    </div>
  );
};

export default SaleProductsTable;
