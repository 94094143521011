export interface OrderEventModel {
  id: number;
  createdDate: Date;
  number: number;
  status: string;
  giftCertificateItems: GiftCertificateItem[];
}
export interface GiftCertificateItem {
  id: number;
  vendorName: string;
  faceValueName: string;
  price: number;
  vendorId: number
}


export const mapOrderStatus = (status: string) => {
  switch (status){
    case 'Placed':
      return 'Принят в обработку';
    case 'Verified':
      return 'Проверен';
    case 'Paid':
      return 'В обработке';
    case 'Approved':
      return 'Подтвержден';
    case 'PartiallyFormed':
      return 'Частично сформирован';
    case 'Formed':
      return 'Сформирован';
    case 'Delivered':
      return 'Доставлен';
    case 'PartiallyDelivered':
      return 'Частично доставлен';
    case 'Cancelled':
      return 'Отменен';
    case 'GiftCertificatesBooked':
      return 'Сертификаты забронированы';
    case 'PartiallyGiftCertificatesBooked':
      return 'Сертификаты частично забронированы';
  }
}
