import React, {ReactNode} from 'react';
import "./AlertRow.css";
import classNames from "classnames";

interface AlertRowProps{
    children:ReactNode;
    type?: "default" | "end"
}

const AlertRow = (props:AlertRowProps) => {
    return (
        <div className={classNames({"alert-row": true, "alert-row--end": props.type == "end"})}>
            {props.children}
        </div>
    );
};

export default AlertRow;