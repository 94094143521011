import React from "react";
import "./WaveCard.css";
import Point from "../Point/Point";

interface WaveCardProps {
  background: string;
  title: string;
}

const WaveCard = (props: WaveCardProps) => {
  return (
    <div className={"wave-card"}>
      <img
        alt={""}
        className={"wave-card__background"}
        src={props.background}
      />
      <div className={"wave-card__title"}>{props.title}</div>
      <div className={"wave-card__separator"} />
      <div className={"wave-card__points"}>
        <div className={"wave-card__point-success"}>
          <div className={"wave-card__point-title"}>Вы заработали</div>
          <div className={"wave-card__point-value"}>
            <Point amount={2500} />
          </div>
        </div>
        <div className={"wave-card__point-await"}>
          <div className={"wave-card__point-title"}>Баллы в обработке</div>
          <div className={"wave-card__point-value"}>
            <Point amount={117000} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaveCard;
