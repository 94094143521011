import React, {Fragment, useState} from "react";
import "./CartPage.css";
import {observer} from "mobx-react-lite";
import CartStickPanelContainer from "../containers/CartStickPanelContainer";
import CartItemListContainer from "../containers/CartItemListContainer";
import ConfirmationOrderModalWindow from "../components/ModalWindows/ConfirmationOrderModalWindow";
import {useHistory} from "react-router-dom";
import {useStores} from "../store";

const CartPage = () => {
  const [showModalWindow, setShowModalWindow] = useState(false);
  const [disabledOrderButton, setDisabledOrderButton] = useState(false);
  const history = useHistory();
  const { store }  = useStores()

  const onShowWindow = () => {
    setShowModalWindow(true);
  };

  const onCreateOrder = () => {
    setDisabledOrderButton(true);
    store.orders.addOrder(successCallback)
  };

  const successCallback = () =>{
    setShowModalWindow(false);
    setDisabledOrderButton(false);
    store.cart.clear()
    history.push("/thank-for-order")
  }

  const onCloseWindow  = () => {
    setShowModalWindow(false);
  };

  return (
    <Fragment>
      <div className={"cart-page"}>
        <div className={"cart-page__title"}>Корзина</div>
        <div className={"cart-page__body"}>
          <div className={"cart-page__products"}>
            <CartItemListContainer />
          </div>
          <div className={"cart-page__stick-container"}>
            <div className={"cart-page__stick-panel"}>
              <CartStickPanelContainer onCreateOrder={onShowWindow} />
            </div>
          </div>
        </div>
      </div>
      {showModalWindow && (
        <ConfirmationOrderModalWindow
          orderDisabled={disabledOrderButton}
          onCreateOrder={onCreateOrder}
          onClose={onCloseWindow}
          createOrderRequestState={store.orders.addOrderRequestState}
        />
      )}
    </Fragment>
  );
};

export default observer(CartPage);
