import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import "./CartWidget.css";
import classNames from "classnames";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import NumberFormat from "react-number-format";

interface CartWidgetProps {
  sum: number;
  points: number;
}

const CartWidget = (props: CartWidgetProps) => {
  useEffect(() => {
    setState(!state);
  }, [props.sum]);

  const [state, setState] = React.useState<boolean>(true);

  return (
    <NavLink to={"/cart"}>
      <div className={"cart-widget"}>
        <div className={"cart-widget__total"}>
          <SwitchTransition>
            <CSSTransition
              key={(state as unknown) as string}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div
                className={classNames({
                  "cart-widget__total-text": true,
                  "cart-widget__total-text--overflow": props.sum > props.points,
                })}
              >
                <NumberFormat
                    value={props.sum}
                    displayType={"text"}
                    thousandSeparator={" "}
                />
                 {" "} B
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </NavLink>
  );
};

export default CartWidget;
