import React, {Fragment, useEffect, useState} from "react";
import FormRow from "../Form/FormRow";
import TextInput from "../TextInput/TextInput";
import Form from "../Form/Form";
import FormSection from "../Form/FormSection";
import UploadDropzone from "../UploadDropzone/UploadDropzone";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import Datalist from "../Datalist/Datalist";
import {WaveProductModel} from "../../types/WaveProductModel";
import Table from "../Table/Table";
import CartCounter from "../CartCounter/CartCounter";
import {AddSaleRequestModel} from "../../types/AddSaleRequestModel";
import SaleTotalSum from "../SaleTotalSum/SaleTotalSum";
import {useHistory, useParams} from "react-router-dom";
import './AddSaleForm.css';
import {observer} from "mobx-react-lite";
import {NavLink} from "react-router-dom";
import {RequestState} from "../../types/RequestState";
import {useStores} from "../../store";

interface SelectedProduct {
    product: WaveProductModel;
    quantity: number;
}

interface AddSaleFormProps {
    waveProducts: WaveProductModel[];
    getWaveProducts: (waveId: number, search: string) => void;
    onAddSale: (request: AddSaleRequestModel, ok: () => void) => void;
    dealer: string;
    company: string;
    city: string;
    address: string;
    addSaleState: RequestState
}

const AddSaleForm = (props: AddSaleFormProps) => {
    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
        []
    );

    useEffect(() => {
        setAddress(props.address);
        setCompany(props.company);
        setCity(props.city);
        setDealer(props.dealer);
    }, []);

    const history = useHistory();
    const {store} = useStores();
    const [dealer, setDealer] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [files, setFiles] = useState<File[]>([]);
    const [note, setNote] = useState<string>("");
    const [agreementNumber, setAgreementNumber] = useState<string>("");
    const [agreementNumberError, setAgreementNumberError] = useState<boolean>(false);
    const [productsError, setProductsError] = useState<boolean>(false);
    const [filesError, setFilesError] = useState<boolean>(false);
    const [formSent, setFormSent] = useState<boolean>(false);


    useEffect(() => {
        setAgreementNumberError(formSent && agreementNumber === '');
    }, [agreementNumber, formSent]);

    useEffect(() => {
        if (formSent) {
            setFilesError(files.length === 0);

        }
    }, [formSent, files]);

    useEffect(() => {
        if (formSent) {
            setProductsError(selectedProducts.length === 0);

        }
    }, [formSent, selectedProducts]);

    const addProduct = (id: string) => {
        const product = props.waveProducts.find((x) => x.id === parseInt(id));
        if (product) {
            setSelectedProducts([
                ...selectedProducts,
                {
                    product: product,
                    quantity: 1,
                },
            ]);
            setProductsError(false);
        }

    };

    const removeProduct = (id: string) => {
        const newArr = selectedProducts.filter(
            (x) => x.product.id === parseInt(id)
        );
        setSelectedProducts(newArr);
        setProductsError(newArr.length === 0);
    };

    const decreaseProduct = (id: number) => {
        const index = selectedProducts.findIndex((x) => x.product.id === id);

        if (selectedProducts[index].quantity === 1) {
            let array = [...selectedProducts];
            array.splice(index, 1);
            setSelectedProducts(array);
        }

        if (selectedProducts[index].quantity > 1) {
            let array = [...selectedProducts];
            array[index].quantity--;
            setSelectedProducts(array);
        }
    };

    const increaseProduct = (id: number) => {
        selectedProducts.forEach((p) => {
            if (p.product.id === id) {
                p.quantity++;
            }
        });
        setSelectedProducts([...selectedProducts]);
    };

    const addSale = () => {
        setFormSent(true);
        if (agreementNumber === '') {
            setAgreementNumberError(true);
            return;
        }

        if (selectedProducts.length == 0) {
            setProductsError(true);
            return;
        }

        console.log('files.length: ', files.length)
        if (files.length == 0) {
            setFilesError(true);
            return;
        }

        props.onAddSale(
            {
                additionalSaleInfo: [
                    {name: "address", value: address},
                    {name: "city", value: city},
                    {name: "company", value: company},
                    {name: "dealer", value: dealer},
                ],
                products: selectedProducts.map((p) => {
                    return {productId: p.product.id, quantity: p.quantity};
                }),
                files,
                note,
                agreementNumber
            },
            () => {
                history.push("/waves/1");
            }
        );
    };

    const selectedProductsTotalSum = () => {
        let sum = 0;
        selectedProducts.forEach((p) => {
            sum += p.product.point * p.quantity;
        });
        return sum;
    };

    return (
        <Form>
            {productsError && <div className="add-sale__error">Добавьте хотя бы один товар</div>}
            {filesError && <div className="add-sale__error">Приложите файлы, подтверждающие продажу</div>}

            <FormRow>
                <TextInput
                    id={"dealer"}
                    label={"Дилер"}
                    onChange={setDealer}
                    value={dealer}
                />
                &nbsp;
                <Button onClick={() => history.push('/profile')} size={ButtonSize.Big} style={ButtonStyle.Primary} type={[ButtonType.Transparent]}>Изменить</Button>
                {/*<NavLink className="add-sale__change-dealer-link" to="/profile">Изменить</NavLink>*/}
            </FormRow>
            <FormRow>
                <TextInput
                    id={"company"}
                    label={"Точка"}
                    onChange={setCompany}
                    value={company}
                />
            </FormRow>
            <FormRow>
                <TextInput
                    id={"city"}
                    label={"Город"}
                    onChange={setCity}
                    value={city}
                />
            </FormRow>
            <FormRow>
                <TextInput
                    id={"address"}
                    label={"Адрес точки"}
                    onChange={setAddress}
                    value={address}
                />
            </FormRow>
            <FormSection title={"Добавление товаров"}>
                <FormRow>
                    <Datalist
                        id={"waveProducts"}
                        add={addProduct}
                        remove={removeProduct}
                        selectedItemIds={selectedProducts.map((p) =>
                            p.product.id.toString()
                        )}
                        items={props.waveProducts.map((product) => {
                            return {
                                id: product.id.toString(),
                                data: <div>{product.name}</div>,
                            };
                        })}
                        label={"Укажите артикул или название товара"}
                        onChangeText={(search) => props.getWaveProducts(1, search)}
                        multiselect
                    />
                </FormRow>
            </FormSection>

            {selectedProducts.length > 0 && (
                <Fragment>
                    <FormRow>
                        <Table
                            header={[
                                {data: "Товар"},
                                {data: "Артикул"},
                                {data: "Баллы"},
                                {data: "Кол-во"},
                            ]}
                            rows={selectedProducts.map((product) => {
                                return {
                                    key: product.product.id.toString(),
                                    columns: [
                                        {data: product.product.name},
                                        {data: product.product.vendorCode},
                                        {data: product.product.point},
                                        {
                                            data: (
                                                <CartCounter
                                                    value={product.quantity}
                                                    onDecrease={() => {
                                                        decreaseProduct(product.product.id);
                                                    }}
                                                    onIncrease={() => {
                                                        increaseProduct(product.product.id);
                                                    }}
                                                />
                                            ),
                                        },
                                    ],
                                };
                            })}
                        />
                    </FormRow>
                    <FormRow>
                        <SaleTotalSum total={selectedProductsTotalSum()}/>
                    </FormRow>
                </Fragment>
            )}

            <FormSection title={"Приложите файлы, подтверждающие продажу"}>
                <FormRow>
                    <UploadDropzone onDrop={setFiles} />
                </FormRow>
            </FormSection>
            <FormRow>
                <TextInput
                    id={"agreementNumber"}
                    label={"Номер документа (договора, чека и т.д.)"}
                    onChange={setAgreementNumber}
                    value={agreementNumber}
                    isError={agreementNumberError}
                    required
                    editable
                />
            </FormRow>
            <FormRow>
                <TextInput
                    id={"comment"}
                    label={"Комментарий"}
                    onChange={setNote}
                    value={note}
                    editable
                />
            </FormRow>
            <FormRow>
                <Button
                    disabled={props.addSaleState == RequestState.Pending || !store.participants.profile?.personalIncomeTaxData}
                    onClick={addSale}
                    size={ButtonSize.Big}
                    style={ButtonStyle.Primary}
                >
                    Отправить на проверку
                </Button>
            </FormRow>
        </Form>
    );
};

export default observer(AddSaleForm);
