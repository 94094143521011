import React, {ReactNode} from "react";
import "./FormRow.css";

interface FormRowProps {
  children: ReactNode;
}

const FormRow = (props: FormRowProps) => {
  return <div className={"form-row"}>{props.children}</div>;
};

export default FormRow;
