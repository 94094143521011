import React from "react";
import "./Footer.css";
import {NavLink} from "react-router-dom";
import CurrentClientProfile from "../../client-profile";
import Authorize from "../Authorize/Authorize";

const Footer = () => {
    return (
        <div className={"footer"}>
            <div className={"footer__row"}>
                <div className={"footer__link"}>
                    <NavLink to={"/products"}>Каталог подарков</NavLink>
                </div>
                <Authorize visible={CurrentClientProfile.sales}>
                    <div className={"footer__link"}>
                        <NavLink to={"/waves/1"}>Акция</NavLink>
                    </div>
                </Authorize>
                <Authorize visible={CurrentClientProfile.userProfile.codes}>
                    <div className={"footer__link"}>
                        <NavLink to={"/profile/codes"}>Активировать код</NavLink>
                    </div>
                </Authorize>
                <div className={"footer__link"}>
                    <NavLink to={"/support"}>Поддержка</NavLink>
                </div>
                <Authorize visible={CurrentClientProfile.sales}>
                    <div className={"footer__link"}>
                        <NavLink to={"/about-products"}>О продукте</NavLink>
                    </div>
                </Authorize>
            </div>
            <div className={"footer__row"}>
                <div className={"footer__logo"}>Powered by Vektor</div>
                <div className={"footer__separate"}/>
                <div className={"footer__copy"}>© 2022 ООО «Вектор»</div>
            </div>
        </div>
    );
};

export default Footer;
