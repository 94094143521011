import React from "react";
import {RouteProps} from "react-router-dom";
import PublicRoute from "../components/PublicRoute";
import {observer} from "mobx-react-lite";

const PublicRouteContainer = (props: RouteProps) => {
  return (
    <PublicRoute isAuthenticated={false} redirect={"/products"} {...props} />
  );
};

export default observer(PublicRouteContainer);
