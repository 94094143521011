import {GiftCertificateModel} from "../types/GiftCertificateModel";
import {ProductDetailModel} from "../types/ProductDetailModel";
import {httpGet} from "../helpers/http";

export const getProductDetail = (id: string): Promise<ProductDetailModel> => {
  return httpGet("/api/v1/products/"+id).then((response) => {
    return response;
  });
};

export const getGiftCertificates = (): Promise<GiftCertificateModel[]> => {
  return httpGet("/api/v1/catalogs/gift-certificates").then((response) => {
    return response;
  });
};
