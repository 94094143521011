import React from 'react';
import {mapOrderStatus} from "../../types/OrderEventModel";
import classNames from "classnames";
import './OrderStatus.css';

interface OrderStatusProps {
    status: string;
}

const OrderStatus = (props: OrderStatusProps) => {
    return (
        <div className={
            classNames({
                "order-status": true,
                "order-status--rejected": props.status == 'Cancelled',
                "order-status--completed": props.status == 'Delivered',
                "order-status--processing": props.status != 'Cancelled' && props.status != 'Delivered'
            })}>{mapOrderStatus(props.status)}</div>
    );
};

export default OrderStatus;
