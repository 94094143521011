import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";

export type PublicRouteProps = {
  isAuthenticated: boolean;
  redirect: string;
} & RouteProps;

const PublicRoute = ({
  isAuthenticated,
  redirect,
  ...routeProps
}: PublicRouteProps) => {
  if (!isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: redirect }} />;
  }
};

export default PublicRoute;
