import React, {useEffect} from 'react';
import {useStores} from "../store";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import "./WaveProductsPage.css";
import classNames from "classnames";
import Title from "../components/Title/Title";
import bannerImg from "../assets/images/ZZH_T30382E11_000_01_4_3_4_3.png";

const increasedPointVendorCodes = [
    '31607002',
    '31455000',
    '31455DC0',
    '31606000',
    '33251000',
    '31303000',
    '31721000',
    '31721DC0',
    '31723000',
    '31724000',
    '119706',
    '119707',
    '119709',
    '119710',
    '119711',
    '120438',
    '30384000',
    '30386000',
    '30388000',
    '30393000',
    '30499000'
]

const WaveProductsPage = () => {
    const {store} = useStores();
    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        store.waves.getProducts(parseInt(id), '', increasedPointVendorCodes);

    }, [id]);


    return (
        <div className="wave-products-page">
            <div className="wave-products-banner">
                <img src={bannerImg} alt="banner"/>
                <div className="wave-products-banner__title wave-products-banner__title--red">Двойной бонус за GROHE Blue</div>
            </div>
            <Title m={25} as="h3">Товары, участвующие в акции "Двойной бонус за GROHE Blue" (01.11.2021-31.03.2022)</Title>
            {store.waves.products.length > 0 && <table cellSpacing="2" cellPadding="5" className="wave-product-table">
                <thead>
                <tr className="wave-product-table-row">
                    <th>Артикул</th>
                    <th>Наименование</th>
                    <th className="wave-product-table_point-cell">Стандартные баллы</th>
                    <th className="wave-product-table_point-cell">Баллы по акции</th>
                </tr>
                </thead>
                <tbody>
                {Array.from(store.waves.products).sort((a1, a2) => a1.point > a2.point ? -1 : 1 ).map(product =>
                    <tr className="wave-product-table-row">
                        <td className="wave-product-table_vendorCode-cell">{product.vendorCode}</td>
                        <td>{product.name}</td>
                        <td className="wave-product-table_point-cell">{increasedPointVendorCodes.indexOf(product.vendorCode) == -1 ? product.point : product.point / 2}</td>
                        <td className={classNames({
                                'wave-product-table_point-cell': true,
                                'wave-product-table_point-cell--double': increasedPointVendorCodes.indexOf(product.vendorCode) != -1
                            }
                        )}>{product.point}</td>
                    </tr>
                )}
                </tbody>
            </table>
            }

        </div>
    );
}
;

export default observer(WaveProductsPage);