import React from "react";
import "./Point.css";
import NumberFormat from "react-number-format";
import classNames from "classnames";

export enum PointSize {
    Big,
}

interface PointProps {
    amount: number;
    size?: PointSize;
    isTopUp?: boolean;
}

const Point = (props: PointProps) => {
    return (
        <div className={"point"}>
            <div className={"point__body"}>
                <div className={classNames({
                    "point__amount": true,
                    "point__amount--green": props.isTopUp,
                    "point__amount--big": props.size == PointSize.Big
                })}>
                    {props.isTopUp && "+"}
                    <NumberFormat
                        value={props.amount}
                        displayType={"text"}
                        thousandSeparator={" "}
                    />
                </div>
                <div
                    className={classNames({
                        point__icon: true,
                        "point__icon--big": props.size === PointSize.Big,
                    })}
                />
            </div>
        </div>
    );
};

export default Point;
