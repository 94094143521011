import React, {ReactNode} from "react";
import "./Grid.css";
import classNames from "classnames";

interface GridProps {
  columns: number;
  gap: number;
  children: ReactNode;
}

const Grid = (props: GridProps) => {
  return (
    <div
      className={classNames({
        grid: true,
        [`grid--columns-${props.columns}`]: true,
        [`grid--gap-${props.gap}`]: true,
      })}
    >
      {props.children}
    </div>
  );
};

export default Grid;
