import React, {useEffect} from "react";
import AddSaleForm from "../components/Forms/AddSaleForm";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";

const AddSaleFormContainer = () => {
  const { store } = useStores();

  useEffect(()=>{
    store.waves.getWaves()
  },[])

  return (
    <AddSaleForm
      getWaveProducts={store.waves.getProducts}
      waveProducts={store.waves.products}
      onAddSale={store.waves.addSale}
      addSaleState={store.waves.addSaleState}
      dealer={store.participants.profile?.dealer || ""}
      address={store.participants.profile?.pointAddress || ""}
      company={store.participants.profile?.company || ""}
      city={store.participants.profile?.city || ""}
    />
  );
};

export default observer(AddSaleFormContainer);
