import React from "react";
import "./AddSalePage.css";
import Title from "../components/Title/Title";
import AddSaleFormContainer from "../containers/AddSaleFormContainer";
import CurrentClientProfile from "../client-profile";
import AlertNdfl from "../components/AlertNdfl/AlertNdfl";
import {useStores} from "../store";

const AddSalePage = () => {
  const {store} = useStores();
  return (
    <div className={"create-wave-page"}>
      <div className={"create-wave-page__title"}>
        <Title as={"h1"}>Добавление продажи</Title>
        {CurrentClientProfile.userProfile.ndfl && !store.participants.profile?.personalIncomeTaxData && (
              <AlertNdfl/>
        )}
      </div>
      <div className={"create-wave-page__body"}>
        <div className={"create-wave-page__form"}>
          <AddSaleFormContainer />
        </div>
        <div className={"create-wave-page__description"}>
          <div className={"create-wave-page__description-body"}>
            <div className={"create-wave-page__description-title"}>
              <Title as={"h3"}> Баллы начисляются в конце месяца </Title>
            </div>
            <div className={"create-wave-page__description-text"}>
              Мы проверим Ваши продажи и начислим баллы в последний день месяца
            </div>
          </div>
          <div className={"create-wave-page__description-body"}>
            <div className={"create-wave-page__description-title"}>
              <Title as={"h3"}>
                Обязательно заполните данные для НДФЛ в профиле
              </Title>
            </div>
            <div className={"create-wave-page__description-text"}>
              Это поможет решить вопрос с налогами
            </div>
          </div>
          <div className={"create-wave-page__description-body"}>
            <div className={"create-wave-page__description-title"}>
              <Title as={"h3"}> Приложите файлы, подтверждающие продажу </Title>
            </div>
            <div className={"create-wave-page__description-text"}>
              Это может быть фото чеков, договора и т.д.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalePage;
