import React from "react";
import "./Header.css";
import Logo from "../Logo/Logo";
import NavBar from "../NavBar/NavBar";
import AddSaleButton from "../AddSaleButton/AddSaleButton";
import NavBarMobile from "../NavBarMobile/NavBarMobile";
import CartWidgetContainer from "../../containers/CartWidgetContainer";
import UserBarContainer from "../../containers/UserBarContainer";
import CurrentClientProfile from "../../client-profile";
import Link from "../Link/Link";
import {NavLink} from "react-router-dom";

const Header = () => {
  return (
    <div className={"header"} style={{height: CurrentClientProfile.header?.height ? CurrentClientProfile.header.height : "auto"}}>
      <div className={"header__item"}>
          <NavLink to={"/"}>
            <Logo logo={CurrentClientProfile.logo}/>
          </NavLink>
      </div>
      <div className={"header__item header__item--mob-hide"}>
        <NavBar />
      </div>
      <div className={"header__item header__item--separator"} />
      <div className={"header__item header__item--mob-hide"}>
        <AddSaleButton />
      </div>
      <div className={"header__item"}>
        <CartWidgetContainer />
      </div>
      <div className={"header__item"}>
        <UserBarContainer />
      </div>
      <div className={"header__item header__item--mob-show"}>
        <NavBarMobile />
      </div>
    </div>
  );
};

export default Header;
