import React, {useEffect, useRef, useState} from "react";
import "./UserBar.css";
import {NavLink} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import Arrow, {ArrowType} from "../Arrow/Arrow";
import Point from "../Point/Point";
import {useClickOutside} from "../../hooks/useClickOutside";

interface UserBarProps{
  balance:number;
  name?:string;
}

const UserBar = (props:UserBarProps) => {
  const [wrapperRef, isOpen, setIsOpen]  = useClickOutside(false);
  return (
    <div className={"user-bar"} ref={wrapperRef}>
      <div
        className={"user-bar__body"}
        onClick={() => {
          if(window.matchMedia('(min-width: 786px)').matches) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div className={"user-bar__name"}>
          <div>{props.name}</div>
          <div className={"user-bar__submenu--mob-hide"}>
            <Arrow type={isOpen ? ArrowType.Up : ArrowType.Down} />
          </div>
        </div>
        <div className={"user-bar__balance"}><Point amount={props.balance} /></div>
      </div>
      <CSSTransition
        in={isOpen}
        unmountOnExit
        classNames="submenu"
        timeout={200}
      >
        <div className={"user-bar__submenu"}>
          <div className={"user-bar__submenu-item"}>
            <NavLink className={"user-bar__submenu-link"} to={"/events"}>
              События
            </NavLink>
          </div>
          <div className={"user-bar__submenu-item"}>
            <NavLink className={"user-bar__submenu-link"} to={"/profile"}>
              Настройка профиля
            </NavLink>
          </div>
          <div className={"user-bar__submenu-item"}>
            <NavLink className={"user-bar__submenu-link"} to={"/authentication/logout"}>
              Выйти
            </NavLink>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default UserBar;
