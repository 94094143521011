import React from "react";
import "./ButtonGroup.css";
import classNames from "classnames";

interface ButtonGroupProps {
  buttons: { id: string; name: string;  }[];
  onClick: (id: string) => void;
  activeId: string;
}

const ButtonGroup = (props: ButtonGroupProps) => {
  return (
    <div className={"button-group"}>
      <div className={"button-group__body"}>
        {props.buttons.map((button) => (
          <div
            onClick={() => {
              props.onClick(button.id);
            }}
            key={button.id}
            className={classNames({
              "button-group__button": true,
              "button-group__button--active": button.id === props.activeId,
            })}
          >
            {button.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ButtonGroup;
