import React, {CSSProperties} from "react";

export interface HamburgerButtonProps {
  open: boolean;
  width?: number;
  height?: number;
  strokeWidth?: number;
  color?: string;
  animationDuration?: number;
  onClick: () => any;
}

const HamburgerButton = (props: HamburgerButtonProps) => {
  const width = props.width || 40;
  const height = props.height || 30;
  const halfHeight: number = Math.round(height / 2);
  const isOpen = props.open || false;
  const strokeWidth = props.strokeWidth || 2;
  const halfStrokeWidth = Math.round(strokeWidth / 2);
  const animationDuration = props.animationDuration || 0.4;

  const containerStyle: React.CSSProperties = {
    width,
    height,
    position: "relative",
  };
  const baseStyle: CSSProperties = {
    display: "block",
    height: `${strokeWidth}px`,
    width: "100%",
    background: props.color || "#000",
    transitionTimingFunction: "ease",
    transitionDuration: `${animationDuration}s`,
    transformOrigin: "center",
    marginTop: -halfStrokeWidth,
    position: "absolute",
  };

  const getTransformValue = (
    isOpen: boolean,
    basePos: number,
    rotate: number,
    halfHeight: number
  ) => {
    return `translate3d(0,${
      isOpen ? halfHeight + "px" : basePos + "px"
    },0) rotate(${isOpen ? `${rotate}deg` : "0"})`;
  };

  const firstLineStyle = {
    transform: getTransformValue(isOpen, 0, 45, halfHeight),
  };
  const secondLineStyle = {
    transitionTimingFunction: "ease-out",
    transitionDuration: `${animationDuration / 4}s`,
    opacity: isOpen ? 0 : 1,
    top: halfHeight,
  };

  const thirdLineStyle = {
    transform: getTransformValue(isOpen, height, -45, halfHeight),
  };

  return (
    <div style={containerStyle} onClick={props.onClick}>
      <span style={Object.assign({}, baseStyle, firstLineStyle)} />
      <span style={Object.assign({}, baseStyle, secondLineStyle)} />
      <span style={Object.assign({}, baseStyle, thirdLineStyle)} />
    </div>
  );
};

export default HamburgerButton;
