import {GiftCertificateModel} from "../types/GiftCertificateModel";
import {ProductDetailModel} from "../types/ProductDetailModel";
import {httpGet, httpPostJson} from "../helpers/http";
import {CodeModel} from "../types/CodeModel";
import {CodeActivateResult} from "../types/CodeActivateResult";

export const getCodes = (): Promise<CodeModel[]> => {
  return httpGet("/api/v1/codes").then((response) => {
    return response;
  });
};

export const activateCode = (code: string): Promise<CodeActivateResult> => {
  return httpPostJson("/api/v1/codes/activate", JSON.stringify({code}));
};
