import React from "react";
import Title from "../components/Title/Title";
import img1 from "../assets/images/1.jpg";
import img2 from "../assets/images/6b459d3a3700598b58d60cfa18c9c102.png";
import img3 from "../assets/images/3.jpg";
import img4 from "../assets/images/grohe-blue-pure.jpg";
import img10 from "../assets/images/10.jpg";
import catalogImg from "../assets/images/catalog.jpg";
import "./BookletsPage.css";
import {BookletsItemModel} from "../types/BookletItemModel";
import BookletsItem from "../components/BookletsItem/BookletsItem";

const BookletsPage = () => {
    const bookletsItems: BookletsItemModel[] = [
        {
            imageLink: img10,
            bookletTitle: "СМЕСИТЕЛИ GROHE ДЛЯ КУХНИ",
            bookletText: "",
            bookletShowLink: "http://flip-catalogue.grohe.com/ru_ru/MPL_Russia_Kitchen_2021_ru-ru/pdf/MPLRussiaKitchen2021ruru.pdf"
        },
        {
            imageLink: img2,
            bookletTitle: "Коллекция GROHE для кухни Selection",
            bookletText: "",
            bookletShowLink: "http://flip-catalogue.grohe.com/ru_ru/GROHE-Kitchen-Selection_ru-ru/html5.html"
        },
        {
            imageLink: img4,
            bookletTitle: "GROHE Blue Pure наборы",
            bookletText: "",
            bookletShowLink: "https://storage.yandexcloud.net/vector-public/grohe/549_21_GROHE_BLUE_PURE_CORRECTION_A4.pdf"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_WaterSystems_ru_RU/original/240/GROHE_WaterSystems_ru_RU.jpg",
            bookletTitle: "GROHE ВОДНЫЕ СИСТЕМЫ",
            bookletText: "Вода, изобретенная заново",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_WaterSystems_ru_RU/original/GROHE_WaterSystems_ru_RU.pdf?_ga=2.59784365.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE-SmartControl-Kitchen-Leaflet_ru_RU/original/240/GROHE-SmartControl-Kitchen-Leaflet_ru_RU.jpg",
            bookletTitle: "GROHE SMARTCONTROL",
            bookletText: "ТОЧНОЕ УПРАВЛЕНИЕ ЛЕГКИМИ ПРИКОСНОВЕНИЯМИ",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE-SmartControl-Kitchen-Leaflet_ru_RU/original/GROHE-SmartControl-Kitchen-Leaflet_ru_RU.pdf?_ga=2.164962111.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE-Zedra-Leaflet_ru_RU/original/240/GROHE-Zedra-Leaflet_ru_RU.jpg",
            bookletTitle: "GROHE ZEDRA",
            bookletText: "ЛЕГЕНДА, ИЗОБРЕТЕННАЯ ЗАНОВО",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE-Zedra-Leaflet_ru_RU/original/GROHE-Zedra-Leaflet_ru_RU.pdf?_ga=2.42301605.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: img1,
            bookletTitle: "КУХНЯ GROHE",
            bookletText: "Идеальное сочетание дизайна и функциональности",
            bookletShowLink: "https://storage.yandexcloud.net/vector-public/grohe/Grohe_Kitchen_Channel_Factsheet.pdf"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE-Baulines-Kitchen_ru_RU/original/240/GROHE-Baulines-Kitchen_ru_RU.jpg",
            bookletTitle: "GROHE BAULINE",
            bookletText: "ПРОСТОТА ДЛЯ СОВРЕМЕННОГО ОБРАЗА ЖИЗНИ",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE-Baulines-Kitchen_ru_RU/original/GROHE-Baulines-Kitchen_ru_RU.pdf?_ga=2.164962111.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE_KitchenBook_ru_RU/original/240/GROHE_KitchenBook_ru_RU.jpg",
            bookletTitle: "GROHE ДЛЯ КУХНИ",
            bookletText: "И инновационные Водные системы",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/G/GRO/GROHE_KitchenBook_ru_RU/original/GROHE_KitchenBook_ru_RU.pdf?_ga=2.72775827.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_BestOfBest_Kitchen_ru_RU/original/240/GROHE_BestOfBest_Kitchen_ru_RU.jpg",
            bookletTitle: "КУХНИ",
            bookletText: "Свежие Решения Для вашей Кухни",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_BestOfBest_Kitchen_ru_RU/original/GROHE_BestOfBest_Kitchen_ru_RU.pdf?_ga=2.164436799.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Minta_Touch_ru_RU/original/240/GROHE_Minta_Touch_ru_RU.jpg",
            bookletTitle: "GROHE MINTA TOUCH",
            bookletText: "ЛЮБОВЬ С ПЕРВОГО ПРИКОСНОВЕНИЯ",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Minta_Touch_ru_RU/original/GROHE_Minta_Touch_ru_RU.pdf?_ga=2.164436799.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Blue_Home_Filter_ru_RU/original/240/GROHE_Blue_Home_Filter_ru_RU.jpg",
            bookletTitle: "ФИЛЬТРЫ ДЛЯ GROHE BLUE HOME",
            bookletText: "ВСЯ МАГИЯ В ФИЛЬТРЕ",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Blue_Home_Filter_ru_RU/original/GROHE_Blue_Home_Filter_ru_RU.pdf?_ga=2.131358607.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Blue_Water_Recipe_Book_ru_RU/original/240/GROHE_Blue_Water_Recipe_Book_ru_RU.jpg",
            bookletTitle: "GROHE BLUE",
            bookletText: "РЕЦЕПТЫ НАПИТКОВ",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Blue_Water_Recipe_Book_ru_RU/original/GROHE_Blue_Water_Recipe_Book_ru_RU.pdf?_ga=2.100996097.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Kitchen_Sinks_ru_RU/original/240/GROHE_Kitchen_Sinks_ru_RU.jpg",
            bookletTitle: "КУХОННЫЕ МОЙКИ GROHE",
            bookletText: "Союз Формы И Функциональности",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/GROHE_Kitchen_Sinks_ru_RU/original/GROHE_Kitchen_Sinks_ru_RU.pdf?_ga=2.64483375.1896855911.1619425242-751650543.1616499235"
        },
        {
            imageLink: catalogImg,
            bookletTitle: "Каталог",
            bookletText: "2021",
            bookletShowLink: "https://cdn.cloud.grohe.com/Literature/Brochures/ru_RU/MPLRussia2021ruru/original/MPLRussia2021ruru.pdf?_ga=2.178635296.564294480.1622106355-309805238.1616491329"
        },
    ]

    return (
        <div className="booklets-page">
            <div className="booklets-page__title">
                <Title as="h3">Скачайте или пролистайте наши брошюры</Title>
            </div>
            <div className="booklets">
                {bookletsItems.map((item, index) => (
                    <BookletsItem key={index} item={item} />
                ))}
            </div>
        </div>
    );
};

export default BookletsPage;