import React, {FunctionComponent} from 'react';

interface AuthorizeProps {
    visible: boolean
}

const Authorize: FunctionComponent<AuthorizeProps> = ({visible, children}) => {
    return (
        <>
            {visible && children}
        </>
    );
}

export default Authorize;
