import React, {useState} from "react";
import "./ProductsInWave.css";
import Point from "../Point/Point";

export interface ProductsInWaveItem {
  groupName: string;
  products: { name: string; vendorCodes: string[] }[];
}

interface ProductsInWaveProps {
  title: string;
  points: number;
  items: ProductsInWaveItem[];
  img: string;
}

const ProductsInWave = (props: ProductsInWaveProps) => {
  const [isOpen, setIsOpen] = useState(-1);

  return (
    <div className={"products-in-wave"}>
      <div className={"products-in-wave__head"}>
        <img src={props.img} />
        <div className={"products-in-wave__title"}>{props.title}</div>
        <div className={"products-in-wave__separate"} />
        <div className={"products-in-wave__points"}>
          <Point amount={props.points} />
        </div>
      </div>

      <div className={"products-in-wave__body"}>
        {props.items.map((item, index) => (
          <div className={"products-in-wave__group"}>
            <div
              onClick={() => {
                if (index === isOpen) setIsOpen(-1);
                if (index !== isOpen) setIsOpen(index);
              }}
              className={"products-in-wave__group-name"}
            >
              {item.groupName}
            </div>

            <div className={"products-in-wave__group-products"}>
              {item.products.map((product) => (
                <div
                  key={product.name + index}
                  className={"products-in-wave__product"}
                >
                  <div className={"products-in-wave__product-name"}>
                    {product.name}
                  </div>
                  {isOpen === index && (
                    <div className={"products-in-wave__vendor-codes"}>
                      {product.vendorCodes.map((vendorCode) => (
                        <div className={"products-in-wave__vendor-code"}>
                          {vendorCode}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsInWave;
