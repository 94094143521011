import React from "react";
import IncomeTaxDataForm from "../components/Forms/IncomeTaxDataForm";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";
import {RequestState} from "../types/RequestState";

const IncomeTaxDataFormContainer = () => {
  const { store } = useStores();

  return (
    <IncomeTaxDataForm
      fio={`${store.participants.profile?.lastname} ${store.participants.profile?.firstname} ${store.participants.profile?.surname}`}
      onSubmit={store.participants.addIncomeTaxData}
      personalIncomeTaxData={
        store.participants.profile?.personalIncomeTaxData || null
      }
      pending={
        store.participants.addIncomeTaxDataRequestState === RequestState.Pending
      }
    />
  );
};

export default observer(IncomeTaxDataFormContainer);
