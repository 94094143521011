import React from "react";
import "./CartCounter.css";

interface CartCounterProps {
  onIncrease: () => void;
  onDecrease: () => void;
  value: number;
}

const CartCounter = (props: CartCounterProps) => {
  return (
    <div className={"cart-counter"}>
      <div onClick={props.onDecrease} className={"cart-counter__control"}>
        <div className={"cart-counter__el"}>-</div>
        <div className={"cart-counter__bg"} />
      </div>
      <div className={"cart-counter__value"}>{props.value}</div>
      <div onClick={props.onIncrease} className={"cart-counter__control"}>
        <div className={"cart-counter__el"}>+</div>
        <div className={"cart-counter__bg"} />
      </div>
    </div>
  );
};

export default CartCounter;
