import React, {FC} from "react";
import {BookletsItemModel} from "../../types/BookletItemModel";
import Link, {LinkStyle} from "../Link/Link";
import "./BookletsItem.css";

export type BookletsItemProps = {
    item: BookletsItemModel;
};

const BookletsItem: FC<BookletsItemProps> = (props) => {
    const {imageLink, bookletTitle, bookletText, bookletShowLink} = props.item;
    return (
        <div className="booklets__item">
            <div className="booklet">
                <div className="booklet__image">
                    <img
                        alt=""
                        src={imageLink}
                    />
                </div>
                <div className="booklet__body">
                    <div className="booklet__title">{bookletTitle}</div>
                    <div className="booklet__text">{bookletText}</div>
                    <div className="booklet__link">
                        <Link
                            to={bookletShowLink}
                            style={[
                                LinkStyle.Rounded,
                                LinkStyle.Primary,
                                LinkStyle.Button,
                            ]}
                        >
                            Посмотреть
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookletsItem;