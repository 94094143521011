import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";
import {AUTH_URL, CLIENT_ID, ROOT_HOST, SCOPE} from "../constants";
import {RequestState} from "../types/RequestState";
import {User, UserManager, UserManagerSettings} from "oidc-client";

export class AuthStore {
  user: User | null = null;
  public readonly manager: UserManager;
  private rootStore: RootStore;
  public signinSilentRequestState: RequestState = RequestState.Done;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    let config: UserManagerSettings = {
      authority: AUTH_URL,
      client_id: CLIENT_ID,
      redirect_uri: `${ROOT_HOST}/authentication/login-callback`,
      silent_redirect_uri: `${ROOT_HOST}/silent-callback.html`,
      post_logout_redirect_uri: `${ROOT_HOST}`,
      response_type: "code",
      revokeAccessTokenOnSignout: true,
      scope: SCOPE,
      includeIdTokenInSilentRenew: true,
      automaticSilentRenew: true,
    };
    this.manager = new UserManager(config);
    this.getUser();
  }

  getUser = () => {
    this.manager
      .getUser()
      .then((user) => {
        if (user) this.user = user;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  get isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  signinSilentCallback = () => {
    this.manager
      .signinSilentCallback()
      .then((user) => {
        if (user) this.user = user;
        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin
        );
      })
      .catch((error) => {});
  };

  signinRedirectCallback = () => {
    return this.manager
      .signinRedirectCallback()
      .then((user) => {
        this.user = user;
      })
      .catch((error) => {
        console.log("signinRedirectCallback =" + error);
      });
  };

  signinRedirect = () => {
    this.manager
      .signinRedirect({
        extraQueryParams: {
          client_id: CLIENT_ID,
          emailConfirmRedirect: ROOT_HOST + "/confirm-email",
        },
      })
      .then(() => {})
      .catch((error) => {});
  };

  signinSilent = () => {
    this.signinSilentRequestState = RequestState.Pending;
    this.manager
      .signinSilent()
      .then((user) => {
        this.user = user;
        this.signinSilentRequestState = RequestState.Done;
      })
      .catch((error) => {
        this.signinSilentRequestState = RequestState.Error;
      });
  };

  stopSilentRenew = () => {
    this.manager.stopSilentRenew();
  };

  logout = () => {
    this.manager
      .signoutRedirect()
      .then((_) => {})
      .catch((error) => {});
  };
}
