import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";
import {GiftCertificateModel} from "../types/GiftCertificateModel";
import {getGiftCertificates} from "../services/CatalogService";
import {RequestState} from "../types/RequestState";

export class CatalogStore {
  private _rootStore: RootStore;
  tags: string[] = [];
  giftCertificates: GiftCertificateModel[] = [];
  selectedGiftCertificate: GiftCertificateModel | null = null;
  currentTag: string | null = null;
  getGiftCertificatesState: RequestState = RequestState.Init;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this._rootStore = rootStore;
  }

  selectGiftCertificate(id: number) {
    if(this.giftCertificates.length == 0){
      let interval = setInterval(() => {
        if(this.giftCertificates.length != 0)
        {
          clearInterval(interval);
        }
        this.giftCertificates.forEach((x) => {
          if (x.id == id) {
            this.selectedGiftCertificate = x;
          }
        });
      },200)
    }else {
      this.giftCertificates.forEach((x) => {
        if (x.id == id) {
          this.selectedGiftCertificate = x;
        }
      });
    }

  }

  getGiftCertificates() {
    this.getGiftCertificatesState = RequestState.Pending;
    if(this.giftCertificates.length > 0)
      return Promise.resolve(this.giftCertificates);
    return getGiftCertificates()
      .then((giftCertificates) => {
        this.getGiftCertificatesState = RequestState.Done;
        this.giftCertificates = giftCertificates.filter(x => x.isVisible).sort((a, b) => {
          if (a.position > b.position) {
            return 1; }
          if (a.position < b.position) {
            return -1; }
          return 0;
        });
        this.giftCertificates.forEach((giftCertificate) => {
          giftCertificate.tags.forEach((tag) => {
            if (this.tags.findIndex((x) => x === tag.name) === -1) {
              this.tags.push(tag.name);
            }
          });
          this.tags.sort();
          return this.giftCertificates;
        });
      })
      .catch((error) => {
        this.getGiftCertificatesState = RequestState.Error;
        throw error;
      });
  }

  setCurrentTag(tag: string | null) {
    this.currentTag = tag;
  }

  get filteredGiftCertificate(): GiftCertificateModel[] {
    if (!this.currentTag) return this.giftCertificates;

    return this.giftCertificates.filter((giftCertificate) => {
      let exists = false;
      giftCertificate.tags.forEach((tag) => {
        if (tag.name === this.currentTag) exists = true;
      });
      return exists;
    });
  }
}
