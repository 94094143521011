import {RootStore} from "./RootStore";
import {RequestState} from "../types/RequestState";
import {makeAutoObservable} from "mobx";
import {CodeModel} from "../types/CodeModel";
import {activateCode, getCodes} from "../services/CodesService";
import {CodeActivateResult} from "../types/CodeActivateResult";

export class CodesStore {
  private _rootStore: RootStore;
  codes: CodeModel[] = [];
  activateCodeState: RequestState = RequestState.Done;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this._rootStore = rootStore;
  }

  getCodes = () => {
    return getCodes()
      .then((codes) => {
        this.codes = codes.sort((a1, a2) => a1.startDate > a2.startDate ? -1 : 1);
      })
      .catch(() => {});
  };


  activate = (code: string): Promise<CodeActivateResult> => {
    this.activateCodeState = RequestState.Pending;
    return activateCode(code)
      .then((response) => {
        this.activateCodeState = RequestState.Done;
        return response;
      }).catch((e) => {
        this.activateCodeState = RequestState.Error;
        throw e;
      });
  };
}
