import React from 'react';
import Alert, {AlertType} from "../Alert/Alert";
import Title from "../Title/Title";
import Paragraph from "../Paragraph/Paragraph";

const AlertNdfl = () => {
    return (
        <Alert type={AlertType.Warning}>
            <Title as={"h4"} m={8}>
                Заполните данные для НДФЛ, чтобы получить призы
            </Title>
            <Paragraph>
                Призы, представленные в каталоге в виде сертификатов, являются
                видом вознаграждения (доходом), с которого удерживается НДФЛ по
                ставке 13%.
            </Paragraph>
            <Paragraph>
                Для упрощения получения приза, налог уплачивается организатором
                данной программы, который выступает Вашим налоговым агентом.
                Заполнение анкеты нужно для того, чтобы организатор имел
                возможность оплатить налог без потери времени для Вас.
            </Paragraph>
            <Paragraph>
                <b>
                    Получение приза без уплаты налога является нарушением закона
                </b>
                <br/>
                Именно поэтому организатор берет ответственность на себя. Все
                личные данные являются конфиденциальными и будут засекречены.
            </Paragraph>
        </Alert>
    );
};

export default AlertNdfl;
