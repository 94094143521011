import React, {useState} from "react";
import "./SupportPage.css";
import Form from "../components/Form/Form";
import FormRow from "../components/Form/FormRow";
import TextInput from "../components/TextInput/TextInput";
import ID from "../helpers/id";
import Title from "../components/Title/Title";
import Button, {ButtonSize, ButtonStyle} from "../components/Button/Button";
import {sendMessage} from "../services/MessagesService";

const SupportPage = () => {

    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [pendingSentMessage, setPendingSentMessage] = useState(false);

    let send = () => {
        if (!message) {
            return;
        }
        setPendingSentMessage(true);
        sendMessage({message})
            .finally(() => {
                setMessage('');
                setMessageSent(true);
                setPendingSentMessage(false);
            });
    }
    return (
        <div className={"support-page"}>
            <div className={"support-page__title"}>
                <Title as={"h1"}>Поддержка</Title>
            </div>
            <div className={"support-page__body"}>
                <div className={"support-page__form"}>
                    <div className={"support-page__form-title"}>
                        <Title as={"h3"}>Написать в службу поддержки</Title>
                    </div>

                    <Form>
                        <FormRow>
                            <TextInput
                                label={"Ваше сообщение"}
                                id={ID()}
                                onChange={(value) => setMessage(value)}
                                value={message}
                                editable
                            />
                        </FormRow>
                        <FormRow>
                            <Button
                                onClick={send}
                                size={ButtonSize.Big}
                                style={ButtonStyle.Primary}
                                pending={pendingSentMessage}
                            >
                                Отправить
                            </Button>
                        </FormRow>
                        {messageSent && <FormRow>
                            Сообщение отправлено
                        </FormRow>}
                    </Form>
                </div>
                <div className={"support-page__contacts"}>
                    <div className={"support-page__contacts-title"}>
                        <Title as={"h3"}>Контакты службы поддержки</Title>
                    </div>
                    <div className={"support-page__contact-item"}>
                        <div className={"support-page__contact-title"}>Телефон</div>
                        <div className={"support-page__contact-value"}>
                            +7 (499) 110-00-25
                        </div>
                        <div className={"support-page__contact-text"}>
                            Ежедневно по будням с 10:00 до 18:00 по московскому времени
                        </div>
                    </div>
                    <div className={"support-page__contact-item"}>
                        <div className={"support-page__contact-title"}>Электронная почта</div>
                        <div className={"support-page__contact-value"}>
                            support@vektor-adv.ru
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportPage;
