import React from "react";
import PrivateRoute from "../components/PrivateRoute";
import {RouteProps} from "react-router-dom";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";

const PrivateRouteContainer = (props: RouteProps) => {
  const { store } = useStores();

  return (
    <PrivateRoute
      isAuthenticated={store.auth.isAuthenticated}
      redirect={"/authentication/login"}
      {...props}
    />
  );
};

export default observer(PrivateRouteContainer);
