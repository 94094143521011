import "./Table.css";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import React from "react";

export interface TableHeaderColumn {
  data: any;
}

export interface TableColumn {
  data: any;
}

export interface TableRow {
  key: string;
  columns: TableColumn[];
}

interface TableProps {
  header: TableHeaderColumn[];
  rows: TableRow[];
  pending?: boolean;
  isZebra?: boolean
}

const Table = (props: TableProps) => {
  return (
    <table className="table">
      <thead className="table__header">
        <tr className="table__row table__row--header">
          {props.header.map((column, index) => (
            <td
              key={"head_" + index}
              className="table__column table__column--header"
            >
              {column.data}
            </td>
          ))}
        </tr>
      </thead>
      <tbody className="table__body">
        {props.rows.map((row) => (
          <tr key={row.key} className={classNames({"table__row": true, "table__row--zebra": props.isZebra})}>
            {row.columns.map((column, index) => (
              <td key={row.key + "_" + index} className="table__column">
                {column.data}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
