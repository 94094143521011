import "./Alert.css";
import classNames from "classnames";
import {ReactNode} from "react";

export enum AlertType {
    Warning
}

interface AlertProps {
    children: ReactNode;
    type: AlertType;
}

const Alert = (props: AlertProps) => {
    const classes = classNames({
        alert: true,
        "alert--warning": props.type === AlertType.Warning,
    });

    return (
        <div className={classes} role="alert">
            {props.children}
        </div>
    );
};

export default Alert;