import React, {useEffect} from "react";
import Point from "../Point/Point";
import "./SaleTotalSum.css";
import {CSSTransition, SwitchTransition} from "react-transition-group";

interface SaleTotalSum {
  total: number;
}

const SaleTotalSum = (props: SaleTotalSum) => {
  useEffect(() => {
    setState(!state);
  }, [props.total]);

  const [state, setState] = React.useState<boolean>(true);

  return (
    <div className={"total"}>
      <div className={"total__body"}>
        <div className={"total__text"}>Вы получите:</div>
        <SwitchTransition>
          <CSSTransition
            key={(state as unknown) as string}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            <div className={"total__value"}>
              <Point amount={props.total} />
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div className={"total__hint"}>Баллы будут начислены в конце месяца</div>
    </div>
  );
};

export default SaleTotalSum;
