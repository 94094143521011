import React from "react";
import "./CartItem.css";
import CartCounter from "../CartCounter/CartCounter";
import {CartItemModel} from "../../types/CartItemModel";
import NumberFormat from "react-number-format";

interface CartItemProps {
  item: CartItemModel;
  onIncrease: (id: string) => void;
  onDecrease: (id: string) => void;
  onRemove: (id: string) => void;
}

const CartItem = (props: CartItemProps) => {
  return (
    <div className={"cart-item"}>
      <div className={"cart-item__image"}>
        <img alt="" src={props.item.cartImagePath} />
      </div>
      <div className={"cart-item__body"}>
        <div className={"cart-item__title"}>
          {props.item.name}, {props.item.faceValue}
        </div>
        <div className={"cart-item__line"}>
          <div className={"cart-item__name"}>Цена</div>
          <div className={"cart-item__value"}>
            <NumberFormat
              value={props.item.price}
              displayType={"text"}
              thousandSeparator={" "}
            />{" "}
            B
          </div>
        </div>
        <div className={"cart-item__line"}>
          <div className={"cart-item__name"}>Кол-во</div>
          <div className={"cart-item__value"}>
            <CartCounter
              onIncrease={() => {
                props.onIncrease(props.item.id);
              }}
              onDecrease={() => {
                props.onDecrease(props.item.id);
              }}
              value={props.item.quantity}
            />
          </div>
        </div>
        <div className={"cart-item__line"}>
          <div className={"cart-item__name"}>Всего</div>
          <div className={"cart-item__value"}>
            <NumberFormat
              value={props.item.price * props.item.quantity}
              displayType={"text"}
              thousandSeparator={" "}
            />{" "}
            B
          </div>
        </div>
      </div>
      <div
        className={"cart-item__close"}
        onClick={() => {
          props.onRemove(props.item.id);
        }}
      >
      </div>
    </div>
  );
};

export default CartItem;
