import React from "react";
import Title from "../components/Title/Title";
import "./AboutProductsPage.css";
import Paragraph from "../components/Paragraph/Paragraph";
import Link from "../components/Link/Link";
import classNames from "classnames";

const AboutProductsPage = () => {
  return (
    <div className="about-products-page">
      <div
        className={classNames("about-products-page__title", "about-products-page__title--main")}
      >
        <Title as="h1">О продукте</Title>
      </div>

      <div className="about-products-page__text">
        GROHE десятилетиями выпускает на рынок многочисленные сантехнические
        изделия для ванной комнаты и кухни – новаторские, конструктивно
        продуманные, отмеченные наградами. Специалисты GROHE выполняют литье,
        гальванизацию, сборку, шлифовку и покрытие изделий, доводя их до
        совершенства. Из сочетания подлинного мастерства и сосредоточенной
        интеллектуальной работы рождается непревзойденная продукция, которая
        впечатляет всегда актуальным дизайном, долговечным качеством и
        уникальными функциями, создающими чистое удовольствие от общения с водой
        – “Pure Freude an Wasser”.
      </div>
      <div className="about-products-page__title">
        <Title as="h3">Ценности нашего бренда</Title>
      </div>
      <div className="about-products-page__text">
        Дизайн, качество, технологии и экологичность. Четыре составляющие нашего
        бренда, лежащие в основе производства изделий GROHE. Мы устанавливаем
        самые высокие стандарты, чтобы обеспечить вам непревзойденное
        удовольствие каждый раз когда Вы включаете смеситель GROHE или
        принимаете душ.
      </div>
      <div className="about-products-page__section">
        <div className="about-products-page__section-title">
          <Title as="h3">Видео о продукте GROHE</Title>
        </div>
        <div className="about-products-page__section-body">
          <div className="about-products-page__section-video">
            <iframe
              width="698"
              height="350"
              src="https://www.youtube.com/embed/videoseries?list=PLAsqg0NQuOi91tnYmQE5NXYV1U69E7nOe"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="about-products-page__section-video-link">
              <Link
                to="https://www.youtube.com/watch?v=Susbew64czE&list=PLAsqg0NQuOi91tnYmQE5NXYV1U69E7nOe"
              >
                Перейти на канал производителя
              </Link>
            </div>
          </div>
          <div className="about-products-page__section-content">
            <Title as="h3" thin m={25}>
              Что вы представляете, когда задумываетесь об источнике воды?
            </Title>
            <Paragraph think>
              Быть может, теплый летний дождь или родник, бьющий мощными
              потоками? Или быструю реку и освежающий морской бриз? Или
              бутылочку любимой минеральной воды?
            </Paragraph>
            <Paragraph think>
              GROHE считает, что источник воды находится ближе к дому. Это
              Unbottled water.
            </Paragraph>
            <Paragraph think>
              GROHE Blue Home – это водная система, идеально подходящая для
              семьи. Всего один смеситель обеспечивает вас и фильтрованной, и
              обычной водой. Это очень простое и эффективное решение для кухни:
              левый рычаг отвечает за чистую фильтрованную воду, правый – за
              обычную водопроводную.
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutProductsPage;
