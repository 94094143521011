import React, {useState} from 'react';
import Form from "../Form/Form";
import FormRow from "../Form/FormRow";
import TextInput, {TextInputSize} from "../TextInput/TextInput";
import Button, {ButtonSize, ButtonStyle} from "../Button/Button";
import {useStores} from "../../store";
import './ActivateCode.css'
import {observer} from "mobx-react-lite";

interface ActivateCodeProps {
    onActivated: () => void;
}

const ActivateCode = ({onActivated}: ActivateCodeProps) => {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [code, setCode] = useState('');
    const {store} = useStores();

    const activateCode = () => {
        store.codes.activate(code)
            .then((response) => {
                if (response.isValid) {
                    setCode('');
                    setSuccess('Код успешно активирован')
                    setError('');
                    onActivated();
                    store.participants.getBalance();
                } else {
                    setSuccess('');
                    setError(response.errorMessage!);
                }
            });
    }

    return (
        <div className="activate-code">
            <Form>
                {error && <div className="activate-code-message activate-code-message--error">{error}</div>}
                {success && <div className="activate-code-message activate-code-message--success">{success}</div>}
                <div className="activate-code-form">
                    <FormRow>
                        <TextInput
                            size={TextInputSize.Middle}
                            id={"code"}
                            label={"Код"}
                            onChange={(value) => {
                                setCode(value);
                                setSuccess('')
                            }}
                            isError={error != ''}
                            value={code}
                            editable
                            required
                        />
                        &nbsp;
                        <Button onClick={activateCode} size={ButtonSize.Big}
                                disabled={code == ''}
                                style={ButtonStyle.Primary}>Активировать</Button>
                    </FormRow>
                </div>
            </Form>
        </div>
    );
};

export default observer(ActivateCode);
