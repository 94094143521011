import React from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import ProductsPage from "./ProductsPage";
import CartPage from "./CartPage";
import ProductDetailPage from "./ProductDetailPage";
import "./_AuthorizedLayout.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SupportPage from "./SupportPage";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import WavesPage from "./WavesPage";
import AboutProductsPage from "./AboutProductsPage";
import AboutCompanyPage from "./AboutCompanyPage";
import ThankForOrderPage from "./ThankForOrderPage";
import AddSalePage from "./AddSalePage";
import EventsPage from "./EventsPage";
import ProfilePage from "./ProfilePage";
import WaveDetailsPage from "./WaveDetailsPage";
import ThankForAddSalePage from "./ThankForAddSalePage";
import BookletsPage from "./BookletsPage";
import ConfirmEmail from "./ConfirmEmail";
import WaveProductsPage from "./WaveProductsPage";
import ActivateCodePage from "./ActivateCodePage";
import OrderDetailsPage from "./OrderDetailsPage";
import ServerNotifications from "../components/ServerNotifications/ServerNotifications";
import CurrentClientProfile from "../client-profile";

const AuthorizedLayout = () => {
  let location = useLocation();
  return (
      <div className={"layout"}>
        <div className={"layout__header"}>
          <div className={"layout__container"}>
            <ServerNotifications />
            <Header />
          </div>
        </div>
        <div className={"layout__body"}>
          <div className={"layout__container"}>
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="page" timeout={300}>
                <Switch location={location}>
                  <Route path="/products/:id" component={ProductDetailPage} />
                  <Route path="/products" component={ProductsPage} />
                  <Route path="/cart" component={CartPage} />
                  <Route path="/profile/codes" component={ActivateCodePage} />
                  <Route path="/profile" component={ProfilePage} />
                  <Route path="/events" component={EventsPage} />
                  <Route path="/support" component={SupportPage} />
                  <Route exact path="/waves/create" component={AddSalePage} />
                  <Route exact path="/waves/:id" component={WaveDetailsPage} />
                  <Route exact path="/waves/:id/products" component={WaveProductsPage} />
                  <Route exact path="/waves" component={WavesPage} />
                  <Route path="/about-products" component={AboutProductsPage} />
                  <Route path="/about-company" component={AboutCompanyPage} />
                  <Route path="/booklets" component={BookletsPage} />
                  <Route
                    path="/thank-for-add-sale"
                    component={ThankForAddSalePage}
                  />
                  <Route
                    path="/thank-for-order"
                    component={ThankForOrderPage}
                  />
                  <Route path="/confirm-email" component={ConfirmEmail} />
                  <Route path="/orders/:id" component={OrderDetailsPage} />
                  <Redirect to={CurrentClientProfile.defaultUrl || "/products"} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
        <div className={"layout__footer"}>
          <div className={"layout__container"}>
            <Footer />
          </div>
        </div>
      </div>
  );
};

export default AuthorizedLayout;
