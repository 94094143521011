export interface Dealer {
    id: number;
    name: string;
}

export const dealerItems: Dealer[] = [
    {
        id: 1,
        name: "«GROHE» (ООО ГРОЭ)"
    },
    {
        id: 2,
        name: "«Красная Гора»"
    },
    {
        id: 3,
        name: "«CVT» Группа компаний"
    },
    {
        id: 4,
        name: "«100м3 Групп»"
    },
    {
        id: 5,
        name: "«Премьер-БТ»"
    },
    {
        id: 6,
        name: "«Тетрасис» (Нижний Новгород)"
    },
    {
        id: 7,
        name: "«Посейдон»"
    },
    {
        id: 8,
        name: "«СЭФИ» (Санкт-Петербург)"
    },
    {
        id: 9,
        name: "«ЦВТ» (Екатеринбург)"
    },
    {
        id: 10,
        name: "«VARKA25»(Владивосток)"
    },
    {
        id: 11,
        name: "«Элитбыттех»(Калининград)"
    },
    {
        id: 12,
        name: "«Мир Кухни»(Новосибирск)"
    }
]
