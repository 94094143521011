export interface SaleProductModel {
  id: number;
  name: string;
  point: number;
  status: SaleProductStatus;
  vendorCode: string;
  quantity: number;
}

export enum SaleProductStatus {
  /// <summary>
  /// Неизвестный
  /// </summary>
  Unknown = 0,

  /// <summary>
  /// В обработке
  /// </summary>
  Created = 1,

  /// <summary>
  /// Зачислено
  /// </summary>
  Success = 2,

  /// <summary>
  /// Отклонена
  /// </summary>
  Rejected = 3
}