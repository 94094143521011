import React, {Fragment} from "react";
import "./Slide.css";
import Point from "../Point/Point";
import Button, {ButtonSize, ButtonStyle} from "../Button/Button";
import classNames from "classnames";

interface SlideProps{
    background:string;
    title1:string;
    points1:number;
    title2:string;
    points2:number;
    one?:boolean;
    buttonText?:string;
    buttonOnClick?:()=>void;
    opacity?: number;
    color?: "red"
}

const Slide = (props:SlideProps) => {
  return (
    <div className={"slide"}>
        <img className={"slide__background"} style={{opacity: props.opacity??0.8}} src={props.background} />
        <div className={"slide__body"}>

            {props.one ?
                <div className={"slide__product slide__product--one"}>
                    <div className={"slide__spread"} />
                    <div className={classNames({"slide__title": true, "slide__title--red": props.color === "red"})} >
                        <span className={classNames({"slide__title-text--red": props.color === "red"})}>{props.title1}</span>
                    </div>
                    <div className={"slide__points"}>
                        {props.points1 != 0 && <Point amount={props.points1} />}
                        {props.buttonText && <Button onClick={props.buttonOnClick!} size={ButtonSize.Middle} style={props.color == "red" ? ButtonStyle.Red : ButtonStyle.Primary}>{props.buttonText}</Button>}
                    </div>
                </div>
                :
            <Fragment>
                <div className={"slide__product"}>
                    <div className={"slide__spread"} />
                    <div className={"slide__title"}>
                        {props.title1}
                    </div>
                    <div className={"slide__points"}>
                        {props.points1 != 0 && <Point amount={props.points1} />}
                    </div>
                </div>
                <div className={"slide__product"}>
                    <div className={"slide__spread"} />
                    <div className={"slide__title"}>
                        {props.title2}
                    </div>
                    <div className={"slide__points"}>
                        {props.points2 != 0 && <Point amount={props.points2} />}
                    </div>
                </div>
            </Fragment>

            }


        </div>

    </div>
  );
};

export default Slide;
