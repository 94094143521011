import React, {useEffect, useState} from "react";
import "./WaveDetailsPage.css";
import classNames from "classnames";
import {useStores} from "../store";
import {useHistory, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import img1 from "../assets/images/1@2x.png";
import img2 from "../assets/images/2@2x.png";
import img3 from "../assets/images/3@2x.png";
import img4 from "../assets/images/4@2x.png";
import img5 from "../assets/images/5@2x.png";
import img6 from "../assets/images/6@2x.png";
import img7 from "../assets/images/7-2@2x.png";
import ProductsInWave from "../components/ProductsInWave/ProductsInWave";
import Grid from "../components/Grid/Grid";
import GridColumn from "../components/Grid/GridColumn";
import SalesTable from "../components/SalesTable/SalesTable";
import bannerImg from "../assets/images/ZZH_T30382E11_000_01_4_3_4_3.png";
import Button, {ButtonSize, ButtonStyle} from "../components/Button/Button";

const MOBILE_MAX_WIDTH = 786;

const WaveDetailsPage = () => {
  const [tab, setTab] = useState("products");
  const { store } = useStores();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState<any>({
    matches: window.innerWidth <= MOBILE_MAX_WIDTH ? true : false
  });

  useEffect(() => {
    store.waves.getWaves();
    store.waves.getSales(parseInt(id));
    window.scrollTo(0, 0)
    let mediaQuery = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`);
    mediaQuery.addEventListener("change", setIsMobile);

    return () => {
      mediaQuery.removeEventListener("change", setIsMobile);
    }
  }, []);

  return (
    <div className={"wave-details-page"}>
      {/*<div className={"wave-details-page__banner"}>*/}
      {/*  <WaveBanner*/}
      {/*    title={"Реализуйте товары GROHE и получайте за них баллы"}*/}
      {/*    background={image}*/}
      {/*    points={store.waves.wave?.point || 0}*/}
      {/*    waitPoints={store.waves.wave?.waitPoint || 0}*/}
      {/*  />*/}


      {/*</div>*/}
      <div className="wave-products-banner">
        <img src={bannerImg} alt="banner"/>
        <div className="wave-products-banner__title wave-products-banner__title--red">Двойной бонус за GROHE Blue</div>
        <div className="wave-products-banner__title" style={{top: "auto", bottom: 0, padding: "5px 10px;"}}>
          <Button onClick={() => history.push("/waves/1/products")} size={ButtonSize.Middle} style={ButtonStyle.Red}>
            Перейти
          </Button>
        </div>

      </div>
      <div className={"wave-details-page__tabs"}>
        <div
          className={classNames({
            "wave-details-page__tab": true,
            "wave-details-page__tab--active": tab === "products",
          })}
          onClick={() => {
            setTab("products");
          }}
        >
          Товары, участвующие в акции
        </div>
        <div
          className={classNames({
            "wave-details-page__tab": true,
            "wave-details-page__tab--active": tab === "sales",
          })}
          onClick={() => {
            setTab("sales");
          }}
        >
          Ваши продажи
        </div>
      </div>

      {tab === "sales" && (
        <div className={"wave-details-page__body"}>
          <div className={"wave-details-page__table"}>
            <SalesTable items={store.waves.sales}/>
          </div>
        </div>
      )}

      {tab === "products" && (
        <div className={"wave-details-page__grid"}>
          <Grid columns={isMobile.matches ? 1 : 2} gap={30}>
            <GridColumn>
              <div className={"wave-details-page__body-item"}>
                <ProductsInWave
                  img={img1}
                  title={"Водные системы GROHE"}
                  points={3000}
                  items={[
                    {
                      groupName: "GROHE Blue Professsional",
                      products: [{name:"",vendorCodes:["31607002"]}],
                    },
                    {
                      groupName: "GROHE Blue Home",
                      products: [{name:"",vendorCodes:["31455000 "," 31455DC0 "," 31606000"]}],
                    },
                    {
                      groupName: "GROHE Red II Duo",
                      products: [{name:"",vendorCodes:["30079001 "," 30079DC1 "," 30325001 "," 30325DC1 "," 30083001 "," 30083A01 "," 30083AL1 "," 30083DA1 "," 30083DC1 "," 30083DL1 "," 30327001 "," 30327A01 "," 30327AL1 "," 30327DA1 "," 30327DC1 "," 30327DL1"]}],
                    },
                    {
                      groupName: "GROHE Red II Mono",
                      products: [{name:"",vendorCodes: [

                              "30080001 "," 30085001 "," 30085A01 "," 30085AL1 "," 30085DA1 "," 30085DL1 "," 30339001 "," 30339DC1 "," 30085DC1"
                          ]}],
                    },
                  ]}
                />
              </div>
            </GridColumn>
            <GridColumn>
              <div className={"wave-details-page__body-item"}>
                <ProductsInWave
                  img={img2}
                  title={"Кухонные мойки GROHE"}
                  points={1000}
                  items={[
                    {
                      groupName:
                        "GROHE Blue Pure (BauCurve / Eurosmart / Minta / Mono)",
                      products: [{name:"",vendorCodes:["30386000 "," 30384000 "," 119710 "," 30388000 "," 120438 "," 119711 "," 119709 "," 119706 "," 119707"]}],
                    },
                    {
                      groupName:
                        "K1000, K800, K700 / K700U, K500, K400 / K400+, K300, K200",
                      products: [{name: "", vendorCodes: [

                              "31581SD0 "," 31582SD0",
                              "31586SD0 "," 31585SD0 "," 31584SD0 "," 31583SD0 ",
                              "31650AP0 "," 31650AT0 "," 31651AT0 "," 31651AP0 "," 31652AP0 "," 31652AT0 "," 31657AP0 "," 31657AT0 "," 31658AP0 "," 31658AT0 "," 31578SD0 "," 31579SD0 "," 31726SD0 "," 31580SD0",


                              "31653AT0 "," 31653AP0 "," 31654AT0 "," 31654AP0 "," 31574SD0 "," 31574AL0 "," 31574GN0 "," 31655AP0 "," 31655AT0 "," 31577SD0 "," 31576SD0 "," 31575SD0",

                              "31571SD0 "," 31644AT0 "," 31644AP0 "," 31645AT0 "," 31645AT0 "," 31646AT0 "," 31646AP0 "," 31646AP0 "," 31648AP0 "," 31647AT0 "," 31647AP0 "," 31649AP0 "," 31649AT0 "," 31572SD0 "," 31588SD0 "," 31573SD0",


                              "31566SD0 "," 31639AT0 "," 31639AP0 "," 31640AT0 "," 31640AP0 "," 31641AT0 "," 31641AP0 "," 31642AT0 "," 31642AP0 "," 31643AT0 "," 31643AT0 "," 31587SD0 "," 31567SD0 "," 31570SD0",

                              "31568SD0 "," 31569SD0",
                              "31563SD0 "," 31564SD0 "," 31565SD0",
                              "31656AT0 "," 31656AT0 "," 31562SD0"

                          ]}],
                    },
                  ]}
                />
              </div>
            </GridColumn>
            <GridColumn>
              <div className={"wave-details-page__body-item"}>
                <ProductsInWave
                  img={img7}
                  title={"Кухонный эксклюзив GROHE"}
                  points={500}
                  items={[
                    {
                      groupName: "Accent / Accent Smart Control",
                      products: [{name:"",vendorCodes:[

                              "30444000 "," 30444DC0 "," 30432000 "," 30432DC0 "," 30423000 "," 30423DC0"

                          ]}],
                    },
                    {
                      groupName: "Scala / Scala Smart Control",
                      products: [{name:"",vendorCodes:[
                              "30442000 "," 30442DC0 "," 30440000 "," 30440DC0 "," 30441000 "," 30441DC0"
                          ]}],
                    },
                    {
                      groupName: "Vento / Vento Smart Control",
                      products: [{name:"",vendorCodes:[
                              "30443000 "," 30443DC0 "," 30437000 "," 30437DC0 "," 30438000 "," 30438DC0 "," 30439000 "," 30439DC0 "," 30434000 "," 30434DC0 "," 30435000 "," 30435DC0 "," 30436000 "," 30436DC0 "," 30425000 "," 30425DC0 "," 30427000 "," 30427DC0 "," 30429000 "," 30429DC0"
                          ]}],
                    },
                    {
                      groupName: "Via / Via 2021 / Via Cosmo",
                      products: [{name:"",vendorCodes:[
                              "30431000 "," 30431DC0 "," 33222000 "," 30496001 "," 30568000 "," 30568DC0 "," 30464000 "," 30464DC0 "," 30465000 "," 30465DC0 "," 30430000 "," 30430DC0"
                          ]}],
                    },
                    {
                      groupName: "Viamo",
                      products: [{name:"",vendorCodes:["30433000"]}],
                    },
                  ]}
                />
              </div>
            </GridColumn>

            <GridColumn>
              <div className={"wave-details-page__body-item"}>
                <ProductsInWave
                  img={img4}
                  title={
                    "Смесители GROHE для мойки коллекций Bau, Start, Euroeco и Costa "
                  }
                  points={300}
                  items={[
                    {
                      groupName:
                        "BauClassic / BauCurve / BauEco / BauEdge / BauFlow / BauLoop",
                      products: [{name:"",vendorCodes:[
                             " 31538001 "," 31230001 "," 31535001 "," 31234001 "," 31688000 "," 31715000 "," 31693000 "," 31696000 "," 31706000 "," 31713000 "," 31680000 "," 31367001 "," 31233001 "," 31368001 "," 31232001 "," 31231001 "," 31536001"

                          ]}],
                    },
                    {
                      groupName: "Costa L / Costa S",
                      products: [{name:"",vendorCodes:[
                          "31831001 "," 31812001 "," 31191001 "," 31187001 "," 30484001 "," 31819001"

                          ]}],
                    },
                    {
                      groupName: "Euroeco / Euroeco Sp / Euroeco Special",
                      products: [{name:"",vendorCodes:[
                          "32752000 "," 32750000 "," 32786000 "," 33912000 "," 32787000"
                          ]}],
                    },
                    {
                      groupName: "GROHE Blue",
                      products: [{name:"",vendorCodes:[
                          "40430001 "," 40412001"
                          ]}],
                    },
                    {
                      groupName: "GROHE Red II",
                      products: [{name:"",vendorCodes:[
                          "40841001"
                          ]}],
                    },
                    {
                      groupName: "StartLoop / StartEdge / StartCurve\n",
                      products: [{name:"",vendorCodes:[
                          "30335DC1 "," 31374001 "," 31369001 "," 31554001"
                          ]}],
                    },
                    {
                      groupName: "Измеритель расхода воды\n",
                      products: [{name:"",vendorCodes:["402167240"]}],
                    },
                  ]}
                />
              </div>
            </GridColumn>
            <GridColumn>
              <div className={"wave-details-page__body-item"}>
                <ProductsInWave
                  img={img5}
                  title={"Аксессуары для кухни GROHE "}
                  points={150}
                  items={[
                    {
                      groupName: "Authentic Дозатор",
                      products: [{name:"",vendorCodes:["40537000"]}],
                    },
                    {
                      groupName: "Contemporary Дозатор",
                      products: [{name:"",vendorCodes:["40536000 "," 40536DC0"]}],
                    },
                    {
                      groupName: "Cosmopolitan Дозатор",
                      products: [{name:"",vendorCodes:["40535000 "," 40535DC0 "," 40535AL0 "," 40535A00 "," 40535DL0 "," 40535DA0 "," 40535GN0 "," 40535GL0 "," 40535BE0"]}],
                    },
                    {
                      groupName: "GROHE Blue",
                      products: [{name:"",vendorCodes:["40404001 "," 40547001 "," 40691001 "," 40422000"]}],
                    },
                    {
                      groupName: "Zedra Дозатор",
                      products: [{name:"",vendorCodes:["40553000 "," 40553DC0"]}],
                    },
                    {
                      groupName: "Дозатор жидкого мыла",
                      products: [{name:"",vendorCodes:["36194000"]}],
                    },
                    {
                      groupName: "Дозатор жидкого мыла, цвет: черная бронза",
                      products: [{name:"",vendorCodes:["40537ZB0"]}],
                    },
                    {
                      groupName:
                        "Сливной гарнитур для мойки с нажимным механизмом",
                      products: [{name:"",vendorCodes:["40986SD0"]}],
                    },
                  ]}
                />
              </div>
            </GridColumn>
            <GridColumn>
              <div className={"wave-details-page__body-item"}>
                <ProductsInWave
                  img={img6}
                  title={" Комплект дооснащения GROHE"}
                  points={100}
                  items={[
                    {
                      groupName:
                        "GROHE Комплект дооснащения для съемной металлической крышки\n",
                      products: [{name:"",vendorCodes:["40984000"]}],
                    },
                  ]}
                />
              </div>
            </GridColumn>
            <GridColumn>
              <div className={"wave-details-page__body-item"}>
                <ProductsInWave
                  img={img3}
                  title={"Смесители GROHE для мойки "}
                  points={500}
                  items={[

                    {
                      groupName: "Ambi / Ambi Cosmopolitan",
                      products: [{name:"",vendorCodes:["30190000 "," 30189000"]}],
                    },
                    {
                      groupName: "Atrio New",
                      products: [{name:"",vendorCodes:["30362AL0 "," 30362DC0 "," 30362000"]}],
                    },
                    {
                      groupName: "Concetto / Concetto New",
                      products: [{name:"",vendorCodes:["32666001 "," 32659001 "," 32663DC3 "," 32663003 "," 32661003 "," 32661DC3 "," 31128001 "," 31128DC1 "," 31210001 "," 31129001 "," 31129DC1 "," 31209001 "," 32667001 "," 31483002 "," 31483DC2 "," 30273001 "," 30273DC1 "," 31491000 "," 31491DC0"]}],
                    },
                    {
                      groupName: "Essence Smart Control / Essence New",
                      products: [{name:"",vendorCodes:["31615000 "," 31615DC0 "," 31615A00 "," 31615AL0 "," 31615BE0 "," 31615EN0 "," 31615DA0 "," 31615DL0 "," 31615GL0 "," 31615GN0 "," 30294000 "," 30294DC0 "," 30294AL0 "," 30294A00 "," 30294DA0 "," 30294DL0 "," 30294BE0 "," 30294EN0 "," 30294GL0 "," 30294GN0 "," 30269000 "," 30269DC0 "," 30269A00 "," 30269AL0 "," 30269DA0 "," 30269DL0 "," 30269GL0 "," 30269GN0 "," 30269BE0 "," 30269EN0 "," 30270000 "," 30270DC0 "," 30270A00 "," 30270AL0 "," 30270DA0 "," 30270DL0 "," 30270GL0 "," 30270GN0 "," 30270BE0 "," 30270EN0"]}],
                    },
                    {
                      groupName: "Eurocube",
                      products: [{name:"",vendorCodes:["31395000 "," 31395DC0 "," 31255000 "," 31255DC0"]}],
                    },
                    {
                      groupName: "Eurodisc / Eurodisc Cosmopolitan",
                      products: [{name:"",vendorCodes:["32259003 "," 31122004 "," 33770002 "," 33772002 "," 32257002 "," 33770001 "," 32257001"]}],
                    },
                    {
                      groupName: "Eurosmart / Eurosmart 2021 / Eurosmart 2015 / Eurosmart New / Eurosmart Cosmopolitan",
                      products: [{name:"",vendorCodes:["30305001","32843002 "," 32843DC2 "," 3284320E "," 31481001 "," 30193000 "," 30193DC0 "," 30195000 "," 32842000 "," 31161000 "," 31170000 "," 33202002 "," 33202DC2 "," 30305000 "," 30305DC0 "," 33281002 "," 33281DC2 "," 3328120E "," 30260002 "," 32482002 "," 32224002 "," 31391002 "," 31509002"]}],
                    },
                    {
                      groupName: "Eurostyle Cosmopolitan",
                      products: [{name:"",vendorCodes:["33975004 "," 31126004 "," 31482003 "," 30221002 "," 31124002 "," 31159002 "," 33977002 "," 33982002"]}],
                    },
                    {
                      groupName: "Get",
                      products: [{name:"",vendorCodes:["32259003"]}],
                    },
                    {
                      groupName:
                        "GROHE Blue / GROHE Blue Pure (BauCurve / Minta / Mono)",
                      products: [{name:"",vendorCodes:["33251000 "," 31303000 "," 30393000 "," 31721000 "," 31721DC0 "," 31723000 "," 31724000"]}],
                    },
                    {
                      groupName: "Minta / Minta Touch / Minta Smart Control",
                      products: [{name:"",vendorCodes:["31613000 "," 31613DC0 "," 31613A00 "," 31613DA0 "," 31360001 "," 31360DC1 "," 31358002 "," 31358DC2 "," 31375000 "," 31375DC0 "," 31375A00 "," 31375AL0 "," 31375DA0 "," 31375DL0 "," 32168000 "," 32168DC0 "," 30274000 "," 30274DC0 "," 30274A00 "," 30274AL0 "," 30274DA0 "," 30274DL0 "," 32917000 "," 32917DC0 "," 32917A00 "," 32917AL0 "," 32917DA0 "," 32917DL0 "," 32917KS0 "," 32918000 "," 32918DC0 "," 3291800E "," 32321002 "," 32321DC2 "," 32321A02 "," 32321AL2 "," 32321DA2 "," 32321DL2 "," 32488000 "," 32488DC0 "," 32488A00 "," 32488AL0 "," 32488DA0 "," 32488DL0 "," 32067000 "," 32322002 "," 32322DC2 "," 32322A02 "," 32322AL2 "," 32322DA2 "," 32322DL2"]}],
                    },
                    {
                      groupName: "Parkfield",
                      products: [{name:"",vendorCodes:["30215001 "," 30215DC1"]}],
                    },
                    {
                      groupName: "Zedra / Zedra Touch / Zedra Smart Control",
                      products: [{name:"",vendorCodes:["31593002 "," 31593DC2 "," 30219002 "," 30219DC2 "," 32294002 "," 32294DC2 "," 32553002 "," 32553DC2 "," 30026002 "," 30026DC2"]}],
                    },
                    {
                      groupName: "K200, K7",
                      products: [{name:"",vendorCodes:["31552SD0 "," 31720SD0,32950000 "," 32950DC0 "," 31379000 "," 31379DC0 "," 31379A00 "," 31379AL0 "," 32950A00 "," 32950AL0"]}],
                    },
                    {
                      groupName: "Система сортировки отходов и мусора GROHE",
                      products: [{name:"",vendorCodes:["40855000 "," 40980000 "," 40981000 "," 40982000 "," 40983000"]}],
                    },
                  ]}
                />
              </div>
            </GridColumn>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default observer(WaveDetailsPage);
