import React, {ReactNode} from 'react';
import classNames from "classnames";
import "./Paragraph.css";

interface ParagraphProps{
    think?:boolean;
    children:ReactNode;
}

const Paragraph = (props:ParagraphProps) => {
    return (
        <p className={classNames({
            "paragraph":true,
            "paragraph--think":props.think
        })}>
            {props.children}
        </p>
    );
};

export default Paragraph;