import React from "react";
import {Redirect, Route, RouteProps, useLocation} from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  redirect: string;
}

const PrivateRoute = ({
  isAuthenticated,
  redirect,
  ...routeProps
}: PrivateRouteProps) => {
  const location =  useLocation()
  if (isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    localStorage.setItem("redirect",location.pathname+location.search)
    return <Redirect to={{ pathname: redirect }} />;
  }
};

export default PrivateRoute;
