import React from "react";
import "./CartItemList.css";
import {CartItemModel} from "../../types/CartItemModel";
import CartItem from "../CartItem/CartItem";
import ID from "../../helpers/id";

interface CartItemListProps {
  items: CartItemModel[];
  onIncrease: (id: string) => void;
  onDecrease: (id: string) => void;
  onRemove: (id: string) => void;
}

const CartItemList = (props: CartItemListProps) => {
  return (
    <div className={"cart-item-list"}>
      {props.items.map((item) => (
        <div className={"cart-item-list__item"} key={ID()}>
          <CartItem
            item={item}
            onDecrease={props.onDecrease}
            onIncrease={props.onIncrease}
            onRemove={props.onRemove}
          />
        </div>
      ))}
    </div>
  );
};

export default CartItemList;
