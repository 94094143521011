import React, {useEffect, useState} from 'react';
import './OrderDetailsPage.css';
import Title from "../components/Title/Title";
import {useHistory, useParams} from "react-router-dom";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";
import Arrow, {ArrowType} from "../components/Arrow/Arrow";
import {GiftCertificateItem, OrderEventModel} from "../types/OrderEventModel";
import Point, {PointSize} from "../components/Point/Point";
import Moment from "moment";
import OrderStatus from "../components/OrderStatus/OrderStatus";
import ResendOrderToEmail from "../components/ResendOrderToEmail/ResendOrderToEmail";
import OrderItem from "../components/OrderItem/OrderItem";
import {saveAs} from "file-saver";

const OrderDetailsPage = () => {
    const {id} = useParams<{ id: string }>();
    const [order, setOrder] = useState<OrderEventModel>();
    const history = useHistory();
    const {store} = useStores();

    const [orderAmount, setOrderAmount] = useState(0);

    const handleResend = () => {
        store.orders.resend(parseInt(id));
    }

    const handleDownload = () => {
        order?.giftCertificateItems.forEach(item => {
            store.orders.downloadItem(parseInt(id), item.id)
                .then(response => response.blob())
                .then(file => {
                    saveAs(file, item.vendorName + "-" + item.faceValueName + ".pdf");
                });
        })

    }


    useEffect(() => {
        store.participants.getProfile();
        store.catalog.getGiftCertificates();
        store.orders.get(parseInt(id))
            .then(response => {
                setOrder(response);
            })
    }, []);

    useEffect(() => {
        let sum = 0;
        order?.giftCertificateItems.forEach(x => {
            sum += x.price;
        });
        setOrderAmount(sum);
    }, [order]);


    const goBackHandle = () => {
        history.push("/events");
    }

    function getCardImage(item: GiftCertificateItem) {
        return store.catalog.giftCertificates.find(x => x.vendorName == item.vendorName)!.cardImage;
    }

    return (
        <div className="order-details-page">
            <div className="order-details-page__header">
                <div className="order-details-page__header-left">
                    <div className="order-details-page__header-title">
                        <div onClick={goBackHandle}>
                            <Arrow className="order-details-page__header-title-arrow" type={ArrowType.Left} size={10}/>
                        </div>
                        <Title as={"h1"}>Заказ №{order?.number}</Title>
                    </div>
                    {order && store.participants.profile &&
                    <ResendOrderToEmail status={order.status} email={store.participants.profile!.email}
                                        onDownload={handleDownload} onResend={handleResend}/>}
                </div>
                <div className="order-details-page__header-middle"></div>
                {order && <div className="order-details-page__header-right">
                    <Point amount={orderAmount} size={PointSize.Big}/>
                    <div className="order-details__wrapper">
                        <span
                            className={"order-details__date"}>{Moment(order.createdDate).format('DD.MM.yyyy HH:mm')}</span>
                        <OrderStatus status={order.status}/>
                    </div>
                </div>}
            </div>
            <div className="order-details-page__body">
                <div className="order-details-page__order-items">
                    {order && store.catalog.giftCertificates.length > 0 && order.giftCertificateItems.map(item =>
                        <OrderItem cardImage={getCardImage(item)} key={item.id} item={item}/>)}
                </div>
            </div>
        </div>
    );
};

export default observer(OrderDetailsPage);
