import {RootStore} from "./RootStore";
import {RequestState} from "../types/RequestState";
import {makeAutoObservable} from "mobx";
import {WaveModel} from "../types/WaveModel";
import {addSale, getSales, getWaveProducts, getWaves,} from "../services/WaveService";
import {WaveProductModel} from "../types/WaveProductModel";
import {AddSaleRequestModel} from "../types/AddSaleRequestModel";
import {SaleModel} from "../types/SaleModel";

export class WaveStore {
  private _rootStore: RootStore;
  waves: WaveModel[] = [];
  products: WaveProductModel[] = [];
  updateWavesState: RequestState = RequestState.Done;
  getProductsState: RequestState = RequestState.Done;
  addSaleState: RequestState = RequestState.Done;
  sales: SaleModel[] = [];
  wave: WaveModel | null = null;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this._rootStore = rootStore;
  }

  getSales = (id: number) => {
    getSales(id)
      .then((sales) => {
        this.sales = sales;
      })
      .catch(() => {});
  };

  addSale = (request: AddSaleRequestModel, ok: () => void): void => {
    this.addSaleState = RequestState.Pending;
    addSale(this.waves[0].id, request)
      .then((response) => {
        this.addSaleState = RequestState.Done;
        ok();
      })
      .catch((error) => {
        console.log(error)
        this.addSaleState = RequestState.Error;
      });
  };

  getWaves = (): void => {
    this.updateWavesState = RequestState.Pending;
    getWaves()
      .then((waves) => {
        this.waves = waves;
        this.wave = waves[0];
        this.updateWavesState = RequestState.Done;
      })
      .catch(() => {
        this.updateWavesState = RequestState.Error;
      });
  };

  getProducts = (waveId: number, vendorCodeStartsWith: string, vendorCodes: string[] = []): void => {
    this.getProductsState = RequestState.Pending;
    getWaveProducts(waveId, vendorCodeStartsWith, vendorCodes)
      .then((products) => {
        this.products = products.sort((a1, a2) => a1.vendorCode.localeCompare(a2.vendorCode));
        this.getProductsState = RequestState.Done;
      })
      .catch(() => {
        this.getProductsState = RequestState.Error;
      });
  };
}
