import React from "react";
import UserBar from "../components/UserBar/UserBar";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";

const UserBarContainer = () => {
  const { store } = useStores();
  return <UserBar name={store.participants.profile?.userName} balance={store.participants.balance} />;
};

export default observer(UserBarContainer);
