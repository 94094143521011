import React, {ReactNode} from 'react';

interface AlertColumnProps{
    children:ReactNode;
}

const AlertColumn = (props:AlertColumnProps) => {
    return (
        <div className={"alert-column"}>
            {props.children}
        </div>
    );
};

export default AlertColumn;