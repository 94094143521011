import React, {useEffect, useRef, useState} from 'react';
import "./SelectList.css";
import classNames from "classnames";
import Arrow, {ArrowType} from "../Arrow/Arrow";
import {CSSTransition} from "react-transition-group";

interface SelectListProps<T> {
    items: T[],
    renderValue: (item: T) => string,
    onSelect?: (selected: T) => void
    value?: T,
    label?: string,
    id?: string,
    isOpen?: boolean,
    disabled?: boolean

}

const SelectList = <T extends object>({
                                          items,
                                          id,
                                          renderValue,
                                          value,
                                          label,
                                          isOpen,
                                          onSelect,
                                          disabled
                                      }: SelectListProps<T>) => {

    const [open, setOpen] = useState<boolean>(isOpen || false);

    let wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: any) => {
        if (wrapperRef !== null && wrapperRef.current !== null) {
            if (!wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
    };

    const toggleOpen = () => {
        if (!disabled)
            setOpen(!open);
    }

    const handleSelect = (item: T) => {
        if (onSelect && item != value) {
            onSelect(item);
        }
    }

    return (
        <div id={id} className={classNames({
            "select-list": true,
            "select-list--opened": open,
            "select-list--disabled": disabled
        })} onClick={toggleOpen}>
            <div className="select-list-value">
                <span className={classNames({
                    "select-list-value__label": true,
                    "select-list-value__label--selected": !!value
                })}>{label}</span>
                <div className="select-list-value__text">{value && renderValue(value)}</div>
                <Arrow className="select-list-value__arrow" size={5} type={open ? ArrowType.Up : ArrowType.Down}/>
            </div>
            <CSSTransition

                in={open}
                unmountOnExit
                classNames="submenu"
                timeout={200}
            >
                <div ref={wrapperRef} className={classNames({
                    "select-list__items": true,
                    "select-list__items--opened": open
                })}>
                    {items.map((item, i) => <div className={classNames({
                        "select-list__item": true,
                        "select-list__item--selected": item == value
                    })} onClick={() => handleSelect(item)}
                                                 key={i}>{renderValue(item)}</div>)}

                </div>
            </CSSTransition>

        </div>

    );
};

export default SelectList;