import React, {useEffect} from "react";
import ConfirmEmailBlock from "../components/ConfirmEmailBlock/ConfirmEmailBlock";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";

interface ConfirmEmailBlockContainerProps{
  token:string
}

const ConfirmEmailBlockContainer = (props:ConfirmEmailBlockContainerProps) => {
  const {store} =  useStores();

  useEffect(()=>{
    store.participants.confirmEmail(props.token)
        .then(() =>{
          store.participants.getProfile();
        })
  },[])

  return <ConfirmEmailBlock state={store.participants.confirmEmailRequestState} />;
};

export default observer(ConfirmEmailBlockContainer);
